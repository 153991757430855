import React, { lazy } from 'react';
import AuthPage from './components/pages/AuthPage';
import LoginPage from './components/pages/LoginPage';
import IsLogined from './utils/IsLogined';
import loadable from '@loadable/component'
import CouponListPage from './components/pages/CouponListPage';
import CouponDetailPage from  './components/pages/CouponDetailPage';
const EditProfilePage =lazy(()=>import('./components/pages/EditProfilePage'));
const MyPage =lazy(()=>import('./components/pages/MyPage'));
const ContactPage=lazy(()=>import('./components/pages/ContactPage'));
const SingleItemPage=lazy(()=>import('./components/pages/SingleItemPage'));
const ProviderPage=lazy(()=>import('./components/pages/ProviderPage'));
const ContentPage=lazy(()=>import('./components/pages/ContentPage'));
const SearchPage=lazy(()=>import('./components/pages/SearchPage'));
const HistoryPage=lazy(()=>import('./components/pages/HistoryPage'));
const RegisterCardPage=lazy(()=>import('./components/pages/RegisterCardPage'));
const ErrorPage = lazy(() =>import('./components/pages/ErrorPage'));
const RegisterCouponPage = lazy(() => import('./components/pages/RegisterCouponPage'));
const ConfirmCouponPage =lazy(() => import('./components/pages/ConfirmCouponPage'));

export const pages = [
  { path: '/', component: <LoginPage/> },
  { path: '/my', component: (<IsLogined><MyPage/></IsLogined>) },
  { path: '/auth', component: <AuthPage /> },
  { path: '/login', component: <LoginPage /> },
  { path: '/edit-profile', component: <IsLogined><EditProfilePage/></IsLogined> },
  { path: '/register-card', component: <IsLogined><RegisterCardPage/></IsLogined>},
  { path: '/history', component: <IsLogined><HistoryPage/></IsLogined>},
  { path: '/search' , component: <IsLogined><SearchPage/></IsLogined>},
  { path: '/content/:id' , component: <IsLogined><ContentPage/></IsLogined>},
  { path: '/singleItem/:id' , component: <IsLogined><SingleItemPage/></IsLogined>},
  { path: '/providers/:id' , component: <IsLogined><ProviderPage/></IsLogined>},
  { path: '/providers' , component: <IsLogined><ProviderPage/></IsLogined>},
  { path: '/contact/:id' , component: <IsLogined><ContactPage/></IsLogined>},
  { path: '/coupon-list' , component: <IsLogined><CouponListPage/></IsLogined>},
  { path: '/register-coupon' , component: <IsLogined><RegisterCouponPage/></IsLogined>},
  { path: '/confirm-coupon' , component: <IsLogined><ConfirmCouponPage/></IsLogined>},
  { path: '/coupon-detail/:id' , component: <IsLogined><CouponDetailPage/></IsLogined>},
  { path: '/error' , component: <ErrorPage/>},
];
