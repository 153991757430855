/** action-creator */
export const setSubscribe= (value) => {
  return { type: 'SET_SUBSCRIBE', value: value };
};
export const resetSubscribe = (value) => {
  return { type: 'RESET_SUBSCRIBE', value: value };
};

/** reducer */
const subscribe = (state = [], action) => {
    switch (action.type) {
      case 'SET_SUBSCRIBE':
          var newArray = action.value.slice();
          return newArray;
      case 'RESET_SUBSCRIBE':
            return [];
      default:
        return state
    }
  }
  export default subscribe;
