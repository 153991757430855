import { firestore } from './firestore';
//import { DocumentReference } from '../../index';
//import * as admin from 'firebase-admin';

const orders = 'orders'

const ref = {
  collection: () => firestore.collection(orders),
  newDoc: () => ref.collection().doc(),
  doc: (orderId) => ref.collection().doc(orderId),
};

export const firestoreOrders = {
  getCollectionRef: () => ref.collection(),
  getRef: (orderId) => ref.doc(orderId),
  getAllData: async () => {
    const userDatas = await firestore
      .getAllDatas(ref.collection())
      .catch((err) => {
        console.log('firestoreProviders getAllUserData err');
        throw err;
      });
    return userDatas;
  },
  getData: async (orderId) => {
    const data = await firestore.get(ref.doc(orderId)).catch((err) => {
      console.log('firestoreProviders  getRef err');
      throw err;
    });
    return data;
  },
  set: async (orderId, data) => {
    await firestore.set(ref.doc(orderId), data, true).catch((err) => {
      console.log('firestoreProviders  set err');
      throw err;
    });
  },
  setNewDoc: async (data) => {
    await firestore.set(ref.newDoc(), data, true).catch((err) => {
      console.log('firestoreProviders  set err');
      throw err;
    });
  },
};

