import React, { useEffect, useState } from "react";
import { AppliedBigCouponCard } from "../molecules/Coupon";
import { StyledFullPaper, StyledHalfFlexWrap } from "../atoms/Paper";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { firestoreProviders } from "../../firebase/firestore/firestoreProviders";
import { firestoreConsumer } from "../../firebase/firestore/firestoreConsumer";
import { useSelector } from "react-redux";
import { BackDropProgress } from "../atoms/BackDropProgress";
import { CouponButton } from "../atoms/Button";
import CustomizedSnackbar from "../atoms/CustomizedSnackbar";
import { ADAPT_COUPON_MESSAGE } from '../../static/messages';

const CouponDetail = () => {
  const history = useHistory();
  const consumerData = useSelector((state) => state.consumerData);
  const consumerId = consumerData ? consumerData.uid : null;
  const couponId = history.location.pathname.replace("/coupon-detail/", "");
  const [couponData, setCouponData] = useState(null);
  const [providerData, setProviderData] = useState(null);
  const [expireDate, setExpireDate] = useState(null);
  const [open, setOpen] = useState(true);
  const [couponStatus, setCouponStatus] = useState(null);
  const [snackState, setSnackState] = useState({
    open: false,
    message: "message",
    sunccess: true,
  });
  useEffect(() => {
    const getCouponData = async () => {
      const allProviders = await firestoreProviders.getAllData();
      let response = await Promise.all(
        allProviders.map(async (provider) => {
          const coupon = await firestoreProviders
            .getCouponData(provider.id, couponId)
            .catch((err) => {
              history.push("/error");
            });
          if (coupon) {
            return {
              coupon: coupon,
              provider: provider,
            };
          }
        })
      );
      response = response ? response.filter((doc) => doc !== undefined) : null;
      if (response) {
        const checkConsumer = await firestoreConsumer
          .getCouponData(consumerId, couponId)
          .catch((err) => {
            history.push("/error");
          });
        setCouponStatus(checkConsumer?.status);
        setExpireDate(checkConsumer?.expireData);
        setCouponData(response[0].coupon);
        setProviderData(response[0].provider);
        setOpen(false);
      } else {
        // クーポンがない旨を表示
        setSnackState({
          open: true,
          message: ADAPT_COUPON_MESSAGE.FAILED_01,
          success: false,
        });
        setTimeout(() => {
          history.push("/coupon-list");
        }, 3000);
      }
    };
    if (!couponId) {
      setSnackState({
        open: true,
        message: ADAPT_COUPON_MESSAGE.FAILED_02,
        success: false,
      });
      setTimeout(() => {
        history.push("/my");
      }, 3000);
      return;
    }
    if (couponId && consumerId) {
      getCouponData();
    }
  }, [couponId, consumerId]);

  return (
    <>
      <StyledFullPaper>
        <StyledHalfFlexWrap>
          <AppliedBigCouponCard
            providerData={providerData}
            couponData={couponData}
            couponId={couponId}
            expireData={expireDate}
            status={couponStatus}
          />
        </StyledHalfFlexWrap>
        <ButtonWrap>
          <CouponButton onClick={() => history.push("/coupon-list")}>
            {"クーポン一覧へ戻る"}
          </CouponButton>
        </ButtonWrap>
      </StyledFullPaper>
      <BackDropProgress open={open} message={"クーポン情報読み込み中..."} />
      <CustomizedSnackbar
        snackState={snackState}
        setSnackState={setSnackState}
      />
    </>
  );
};

export default React.memo(CouponDetail);

const ButtonWrap = styled.div`
  margin: 0 auto ${(props) => props.theme.spacing(2)}px auto;
  text-align: center;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    margin: ${(props) => props.theme.spacing(2)}px;
  }
`;
