import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { customTokenLogin } from '../../firebase/auth/customTokenLogin';
import { Progress } from '../atoms/Progress';
import { StyledFullPaper } from '../atoms/Paper';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { analytics } from '../../firebase/index';
import { analyticsData } from '../../static/data';
import liff from '@line/liff';
import { liffId, consumerUrl } from '../../config';
import * as firebase from 'firebase';
import { CookonLoginIcon } from '../../icons/icon';
import { keyframes } from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const AuthPage = () => {
  const history = useHistory();
  const matches = useMediaQuery('(min-width:600px)');
  const logined = useSelector(state => state.logined)
  const liffUrlQueryParam = useSelector(state => state.liffUrlQueryParam);
  const liffPath = useSelector(state => state.liffPath);
  const firebaseLogin = async () => {
    let redirectParam = '/';
    if(liffPath !== null){
      redirectParam = `${liffPath}/` 
    }
    if (liffUrlQueryParam) {
      for (let i = 0; i < liffUrlQueryParam.length; i++) {
        if (i === 0) {
          redirectParam += `?${liffUrlQueryParam[0]}`;
          continue;
        }
        redirectParam += '?' + liffUrlQueryParam[i];
      }
    }
    firebase.auth().onAuthStateChanged((currentUser) => {
      if (currentUser) {
        if (redirectParam) {
          history.push(redirectParam);
        } else {
          history.push('/my');
        }
      } else {
        liff.ready.then(() => {
          if (!liff.isLoggedIn()) {
              liff.login({});
          }
          const accessToken = liff.getAccessToken();
          if (accessToken) {
            customTokenLogin(accessToken).catch((err) => {
              console.log('ログインに失敗しました');
              history.push('/error'); 
              analytics.logEvent(analyticsData.login_error, { method: 'line' });
              return;
            });
            analytics.logEvent(analyticsData.logoin, { method: 'line' });
          }
        }).catch((err) => console.log(err));
      }
    });
  };

  useEffect(() => {
    if (!logined) {
      firebaseLogin();
    }
  }, [liffUrlQueryParam, liffPath]);

  return (
    <div style={{textAlign: 'center'}}>
      <CustomizeFullPaper>
        {matches && <Progress message={'ログイン処理中...'} />}
        <Wrap>
        <StyledCookonLoginIcon/>
        </Wrap>
      </CustomizeFullPaper>
    </div>
  )
}

export default AuthPage;

const CustomizeFullPaper = styled(StyledFullPaper)`
box-shadow: none;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right:
  text-align: center;
  height: 100vh;
  margin-top: -16px;
  background:white;
  text-align:center;
  }
`;

const rotate = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }

`;

const StyledCookonLoginIcon = styled(CookonLoginIcon)`
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  display:none;
  }
@media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px){
  width :100px;
  height: 100px;
  animation: ${rotate} 7s linear infinite;
　}
}
`;

const  Wrap = styled.div`
margin-top: calc(40vh - 100px);
`;
