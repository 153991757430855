import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import Hidden from '@material-ui/core/Hidden';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    maxWidth: '800px',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      width: '100vw',
      },
  },
  progress: {
    marginTop: '15vw'
  },
}));

export const Progress = (props) => {
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:600px)');
  return (
    <div className={classes.root}>
      <CustomizedLiner />
      <Message component='div'>{props.message}</Message>
    </div>
  );
}

const Message = styled(Typography)`
margin-top: 12.5vw;
margin-bottom: 7vw;
font-size: 20px;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  margin-top:100px;
  margin-bottom: 50px;
  font-size: 28px;
  max-width: 800px;
   }
`;


const CustomizedLiner = styled(LinearProgress)`
width:100vw;
margin-top: 20px;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  max-width: 800px;
   }
`
