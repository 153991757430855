import { firestore } from './firestore';

const consumer = 'consumers';
const hidden = 'hidden';
const stripeInfo = 'stripeInfo';
const subscribe = 'subscribe';
const contents = 'contents';
const coupons = 'coupons';

const ref = {
  collection: () => firestore.collection(consumer),
  doc: (consumerId) => ref.collection().doc(consumerId),
  hiddenRef: (consumerId) => ref.collection().doc(consumerId).collection(hidden).doc(stripeInfo),
  subscribeRef: (consumerId)=> ref.collection().doc(consumerId).collection(subscribe),
  contentsRef: (consumerId)=> ref.collection().doc(consumerId).collection(contents),
  couponsRef: (consumerId) =>  ref.collection().doc(consumerId).collection(coupons),
  couponRef: (consumerId, couponId) =>  ref.collection().doc(consumerId).collection(coupons).doc(couponId),

}

export const firestoreConsumer = {
  getCollectionRef: () => ref.collection(),
  getNewDocRef: () => ref.newDoc(),
  getRef: (guestId) => ref.doc(guestId),
  getSubscribeRef: (guestId) => ref.subscribeRef(guestId),
  getContentsRef:(guestId) => ref.contentsRef(guestId),
  getCouponsRef: (consumerId) =>ref.couponsRef(consumerId),
  getAllData: async () => {
   const userDatas = await firestore.getAllDatas(ref.collection()).catch((err) => {
     console.log('FirestoreOrders getAllData err');
     throw err
   })
   return userDatas;
  },
  getData: async (orderId) => {
   const data = await firestore.get(ref.doc(orderId)).catch((err) => {
     console.log('FirestoreOrders getRef err');
     throw err
   })
    return data;
  },
  getRefStripeInfo:(guestId) => ref.hiddenRef(guestId),
  getCouponData: async(userId, couponId) => {
    return await firestore.get(ref.couponRef(userId, couponId)).catch((err) => {
     console.log('firestoreProviders getContentData err');
     throw err
    })
  },
}
