import { useCallback, useEffect } from 'react';
import * as firebase from 'firebase';
import { useDispatch } from "react-redux";
import { login, logout } from '../redux/action_reducers/logined';
import { setConsumerData, resetConsumerData } from '../redux/action_reducers/consumerData';
import { firestoreConsumer } from './firestore/firestoreConsumer';
import { setCustomerId, resetCustomerId } from '../redux/action_reducers/customerId';
import { firestoreProviders } from './firestore/firestoreProviders';
import { setFollow } from '../redux/action_reducers/follow';
import { useSelector } from 'react-redux';
import { setSubscribe, resetSubscribe } from '../redux/action_reducers/subscribe';
import { setContents, resetContents } from '../redux/action_reducers/contents';
import { setHistory, resetHistory } from '../redux/action_reducers/history';
import { firestoreOrders } from './firestore/firestoreOrders';
import { setStripeInfo } from '../redux/action_reducers/stripeInfo';
import { setCoupons, resetCoupons} from '../redux/action_reducers/coupons';
import { setLike } from '../redux/action_reducers/likes';
import { setSearchData } from '../redux/action_reducers/searchData';
import  { algoliaSearch } from '../algolia';

const FirebaseToRedux = () => {
  const dispatch = useDispatch();
  const follow = useSelector(state => state.follow);
  useEffect(() => {
    observeAuth();
  }, []);

  const observeProfile = useCallback((uid) => {
    const profileDocumentRef = firestoreConsumer.getRef(uid);
    profileDocumentRef.onSnapshot((doc) => {
      dispatch(setConsumerData({
        uid: uid,
        data: doc.data() ? doc.data() : {profile: {}},
      }))
    })
  }, []);

  const observeStripeInfo = useCallback((uid) => {
    const stripeId_ref = firestoreConsumer.getRef(uid).collection('hidden').doc('stripeInfo');
    stripeId_ref.onSnapshot(async (doc) => {
      if (doc.exists) {
        if (doc.data().customerId && doc.data().customerId !== '') {
          dispatch(setStripeInfo({
            status: true,
            cardLast4:doc.data()?.cardLast4,
            brand:doc.data()?.brand
          }));
        } else {
          dispatch(setStripeInfo({
            status: false,
            cardLast4 :null,
            brand: null,
          }));
        }
      } else {
        dispatch(setStripeInfo({
          status: false,
          cardLast4 :null,
          brand: null,
        }));
      }
    }, err => {
      console.log(`Encountered error: ${err}`);
    })
  }, []);

  const observeFollow = useCallback(async(uid)=>{
    const query = firestoreProviders.getCollectionRef();
    let followList = [];
    query.onSnapshot((querySnapshot) => {
      querySnapshot.forEach(async(doc)=>{
        const providerData = await firestoreProviders.getData(doc.id).catch((err) => {
          throw err;
        });
        const docQuery = firestoreProviders.getFollowersRef(uid, doc.id);
        docQuery.onSnapshot((n) => {
          if(n.exists && !(followList.some((u) => u.providerId === doc.id))){
              followList.push({
              providerId: doc.id,
              data: n.data(),
              shopInfo: providerData,
            })
          } else if (!n.exists && followList.some((u) => u.providerId === doc.id)){
            followList = followList.filter((provider) => provider.providerId !== doc.id)
          }
          dispatch(setFollow(followList))
        })
      });
   })
  },[]);
  const observeSubscribe = useCallback((uid) => {
    const query = firestoreConsumer.getSubscribeRef(uid);
    query.onSnapshot((querySnapshot) => {
      const subscribeList = [];
      if (querySnapshot.empty) {
        dispatch(resetSubscribe())
      }
      querySnapshot.forEach(async(doc)=>{
        if(doc.exists) {
          if(doc.data().status === 'active' || doc.data().status === 'inPurchasing' || doc.data().status === 'inActive' || doc.data().status === 'cancel') {
            subscribeList.push({
              id: doc.id,
              data: doc.data(),
            })
          }
        }
        dispatch(setSubscribe(subscribeList))
      });
   })
  },[]);

  const observeContents = useCallback((uid) => {
    const query = firestoreConsumer.getContentsRef(uid);
    query.onSnapshot((querySnapshot) => {
      const contentsList = [];
      if (querySnapshot.empty) {
        dispatch(resetContents())
      }
      querySnapshot.forEach(async(doc)=>{
        if(doc.exists) {
          if(doc.data().status === 'active' || doc.data().status === 'inPurchasing') {
            contentsList.push({
              id: doc.id,
              data: doc.data(),
            })
          }
        }
        dispatch(setContents(contentsList))
      });
   })
  },[]);

    // ここでlikeを更新する
    const observeLikeList = useCallback(async (uid) => {
      const providerList = await firestoreProviders.getAllData().catch((err) => { throw err; })
      if (providerList && providerList.length > 0) {
        const list = [];
        await Promise.all(providerList.map(async (doc) => {
          const allContents = await firestoreProviders.getContents(doc.id);
          if (allContents && allContents.length > 0) {
            await Promise.all((allContents.map(async (n) => {
              const data = n.data;
              const providerId = data ? data.providerId ? data.providerId : null : null;
              const published = data ? data.published ? data.published : false : false;
              if (providerId && published) {
                const response = await firestoreProviders.getChecklikes(n.id, providerId, uid)
                if (response) {
                  list.push(n);
                }
              }
            }
            )))
          }
        }));
        const sortedList = list.length > 0 ? list.sort((a, b) => a.data.createAt.seconds > b.data.createAt.seconds ? 1 : -1) : list;
        dispatch(setLike(sortedList))
      }
    }, []);

  const observeHistory = useCallback((uid)=> {
    const query = firestoreOrders.getCollectionRef().where("consumerRef", "==", firestoreConsumer.getRef(uid))
    query.onSnapshot(async(querySnapshot) => {
      const orderList = [];
      if (querySnapshot.empty) {
        dispatch(resetHistory())
      }
      const docs = querySnapshot.docs;
      await Promise.all(docs.map(async(doc)=>{
        if(doc.exists) {
          const orderType = doc.data()?.orderType;
          const contentRef = doc.data()?.contentRef;
          const providerRef =  doc.data()?.providerRef;
          let contentData = null;
          let providerData =  null;
          if(orderType === "subscription"){
            providerData = providerRef ? await providerRef.get() : null;
          } else {
            contentData = contentRef ? await contentRef.get() : null;
          }
          orderList.push({
            id: doc.id,
            data: doc.data(),
            contentData: contentData ? contentData.data() : null,
            providerData: providerData ? providerData.data() : null,
          });
        }
      }))
      dispatch(setHistory(orderList));
   })
  }, []);

  const observeCoupons = useCallback((uid) => {
    const query =  firestoreConsumer.getCouponsRef(uid).orderBy('createAt','desc');
    query.onSnapshot(async (querySnapshot) => {
      const couponList = []
      if (querySnapshot.empty) {
        dispatch(resetCoupons())
      }
     /* querySnapshot.forEach(async(doc)=>{
          // 追加する場合 (すでにある場合ものは追加しない)
          couponList.push({
          couponId: doc.id,
          data: doc.data()
        })
      });*/
      for (let index = 0; index < querySnapshot.size; index++) {
        const appliedCoupon = querySnapshot.docs[index].data();
        const couponRef = appliedCoupon.couponRef;
        const couponData = await couponRef.get()
        couponList.push({
          couponId: querySnapshot.docs[index].id,
          data: appliedCoupon,
          couponData: couponData.data()
        });
      }
      dispatch(setCoupons(couponList))
    }, err => {
      console.log(`Encountered error: ${err}`);
    })
  }, []);

  const getSearchData =  useCallback(async() => {
    const response = await algoliaSearch();
    const filteredResponse = response ? response.hits.filter((doc) => (doc.published === true)) : response;
    dispatch(setSearchData(filteredResponse));
  },[]);


  const observeAuth = useCallback(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        dispatch(login(null));
        observeProfile(user.uid)
        observeStripeInfo(user.uid);
        observeFollow(user.uid);
        observeSubscribe(user.uid);
        observeContents(user.uid);
        observeHistory(user.uid);
        observeCoupons(user.uid);
        observeLikeList(user.uid);
        getSearchData();
      } else {
        dispatch(logout());
        dispatch(resetConsumerData())
        dispatch(resetCustomerId());
      }
    });
  }, []);





  return (null);
};

export default FirebaseToRedux;
