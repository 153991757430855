// 登録可能な金融機関一覧
export const bankList = [
    {
      label: 'みずほ銀行',
      value: '0001',
    },
    {
      label: '三菱UFJ銀行',
      value: '0005',
    },
    {
      label: '三井住友銀行',
      value: '0009',
    },
    {
      label: 'りそな銀行',
      value: '0010',
    },
    {
      label: '埼玉りそな銀行',
      value: '0017',
    },
    {
      label: 'ジャパンネット銀行',
      value: '0033',
    },
    {
      label: 'セブン銀行',
      value: '0034',
    },
    {
      label: 'ソニー銀行',
      value: '0035',
    },
    {
      label: '楽天銀行',
      value: '0036',
    },
    {
      label: '住信ＳＢＩネット銀行',
      value: '0038',
    },
    {
      label: 'じぶん銀行',
      value: '0039',
    },
    {
      label: 'イオン銀行',
      value: '0040',
    },
    {
      label: 'ゆうちょ',
      value: '9900',
    },
  ];

export const identificationList = [
    {
      label: '運転免許証(表裏両面)',
      value: '1',
    },
    {
      label: 'パスポート',
      value: '2',
    },
    {
      label: '住基カード',
      value: '3',
    },
  ];

/**
 * stripe 用性別
 */


export const genderList = [
  {
    label: '男性',
    value: 'male',
  },
  {
    label: '女性',
    value: 'female',
  },
];


export const castTypeList = [
  '細身',
  '筋肉質',
  '歌うま',
  'ぽっちゃり',
  '妻子持ち',
  '盛り上げ上手',
  '運動神経抜群',
  '方言',
  'オラオラ',
  '服好き',
  '高学歴',
  '映画好き',
  '音楽好き',
  'アニメ好き',
  '飲める人',
  '聞き上手',
  '車持ち',
  'マラデカ',
  'ビジュアル系',
  'ジャニーズ系',
  'EXILE系'
];

export const sexList = [
  {label: "男性", value: "男性"},
  {label: "女性", value: "女性"},
  {label: "その他", value: "その他"},
  {label: "答えたくない", value: "答えたくない"},
];

/**
 * stripe account verified status
 */
export const accountStatus = [
  { status: 'loading', text: '' },
  { status: 'unverified', text: '本人確認が完了していません' },
  { status: 'pending', text: '本人確認実施中' },
  { status: 'verified', text: '本人確認完了済み' },
]

export const limitCommentCharacters = 50;

export const analyticsData = {
  logoin: 'login',
  logout: 'logout',
  purchase: 'purchase',
  cancel_subscription: 'cancelSubscription',
  cancel_purchase: 'cancel_purchase',
  edit: 'edit_profile',
  content_share: 'content_share',
  provider_share: 'provider_share',
  login_error: 'login_error'
}

export const termsUrl = 'https://cookon-lp.web.app/terms';

export const couponStatus = [
  {status: 'active', label:'使用中'},
  {status: 'inActive', label:'使用済'},
  {status: 'expired', label:'期限切れ'}
]

export const contentCategories = {
  free: '無料',
  backNumber: 'バックナンバー',
  membersOnly:  '会員限定',
  limitedTime: '期間限定',
  eventItem: '単品'
}

