import { auth } from '../index.js';
import liff from '@line/liff';

export const firebaseAuthLogout = async () => {
  auth
    .signOut()
    .then(function () {
      console.log('logout');
    })
    .catch(function (error) {
      throw error;
    });
    if (liff.isLoggedIn()) {
      liff.logout();
    }
};
