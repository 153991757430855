/** action-creator */
export const setFollow= (value) => {
  return { type: 'SET_FOLLOW', value: value };
};
export const resetFollow = (value) => {
  return { type: 'RESET_FOLLOW', value: value };
};

/** reducer */
const follow = (state = [], action) => {
    switch (action.type) {
      case 'SET_FOLLOW':
          var newArray = action.value.slice();
          return newArray;
      case 'RESET_FOLLOW':
            return [];
      default:
        return state
    }
  }
  export default follow;
