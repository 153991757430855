import { db } from '../index';

/**Firestoreのメソッドのモジュール　基本的な機能はここに整備して、firestoreフォルダ配下で個別に呼び出して使用する形にする */
export const firestore = {
  collection: (collection) => db.collection(collection),
  // docをrefで取得する
  forceDelete: async (ref) => {
    await ref.delete().catch((err) => {
      throw err;
    })
  },
  set: async (ref, data, merge) => {
    await ref.set(data, { merge: merge === false ? undefined : true }).catch((err) => {
      console.log('Firestore set err');
      throw err;
    })
  },
  getAllDatas: async (ref) => {
    const docs = await ref.get().catch((err) => {
      throw err;
    })
    if (docs.empty) {
      return null;
    }
    const list = [];
    docs.forEach((doc) => {
      list.push({
        id: doc.id,
        data: doc.data(),
      })
    })
    return list;
  },
  get: async (ref) => {
    const doc = await ref.get().catch((err) => {
      throw err;
    });
    const data = doc.data();
    if (!data) {
      return undefined;
    }
    return data;
  },
}
