/** action-creator */
export const setLike = (value) => {
  return { type: 'SET_LIKE', value: value };
};
export const resetLike = (value) => {
  return { type: 'RESET_LIKE', value: value };
};

/** reducer */
const likes = (state = null, action) => {
    switch (action.type) {
      case 'SET_LIKE':
          var newArray = action.value.slice();
          return newArray;
      case 'RESET_LIKE':
            return [];
      default:
        return state
    }
  }
  export default likes;
