export const COPY_PAGELINK_MESSAGE = {
  SUCCESS: 'ページリンクをコピーしました',
  FAILED: 'ページリンクのコピーに失敗しました'
}

export const FOLLOW_PROVIDER_MESSAGE = {
  SUCCESS: 'フォローが完了しました',
  FAILED: 'フォローに失敗しました'
}

export const UNFOLLOW_PROVIDER_MESSAGE = {
  SUCCESS: 'フォローを解除しました',
  FAILED: 'フォロー解除に失敗しました'
}

export const LIKE_MESSAGE = {
  SUCCESS: 'いいねを押しました',
  FAILED: 'いいねに失敗しました'
}

export const GET_DATA_FROM_FIRESTORE_MESSAGE = {
  FAILED: 'データ取得に失敗しました'
}

/**
 * contactのメッセージ
 */

export const POAST_CONTACT_FORM_MESSAGE = {
  SUCCESS: 'お問い合わせが完了しました。教室からの回答をお待ちください',
  FAILED: 'お問い合わせの処理が失敗しました。再度お試しください'
}

/**
 * Replyのメッセージ
 */

export const POST_REPLY_MESSAGE = {
  SUCCESS: '',
  FAILED: 'リプライコメントに失敗しました'
}

export const DELETE_REPLY_MESSAGE = {
  SUCCESS: 'リプライコメントを削除しました',
  FAILED: 'リプライコメントの削除に失敗しました'
}

export const EDIT_REPLY_MESSAGE = {
  SUCCESS: '',
  FAILED: 'リプライコメントの編集に失敗しました'
}

/**
 * subscriptionのメッセージ
 */

export const CANCEL_SUBSCRIPTION_MESSAGE = {
  SUCCESS: '解約処理が完了しました',
  FAILED: '解約処理が失敗しました'
}
/**
 * purchase content
 */
export const PURCHASE_CONTENT_MESSAGE = {
  FAILED: 'クレジットカードが未登録です。モーダルからカード情報を登録してください。'
}

export const ADAPT_COUPON_MESSAGE = {
  FAILED_01: '適用しようとしているクーポンがありません。',
  FAILED_02: '正しいクーポンコード入力されていません。再度URLまたはリンクをご確認ください',
  FAILED_03: '本クーポンは既に適用済みです',
  FAILED_04: '適用しようとしているクーポンがありません。再度URLまたはリンクをご確認ください',
  FAILED_05: '適用しようとしているクーポンは失効済みです',
  FAILED_06: '適用しようとしているクーポンは既に使用済みです',
  FAILED_07: '正しいクーポンコード入力されていません。再度URLまたはリンクをご確認ください'
}

