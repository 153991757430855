import React from 'react';
import styled from 'styled-components';
import { FlexWrap } from './Paper';
import { BigContentIcon, BigPeopleIcon, BigCoupon } from '../../icons/icon';
import Typography from '@material-ui/core/Typography';

export const  NonContent = (props) => {
  return(
    <StyledFlexWrap>
      <BigContentIcon/>
      <Message>{props.message}</Message>
    </StyledFlexWrap>
  )
}
export const  NonFollow = (props) => {
  return(
    <StyledFlexWrap>
      <BigPeopleIcon/>
      <Message>{props.message}</Message>
    </StyledFlexWrap>
  )
}
export const  NonCoupon = (props) => {
  return(
    <CustomizedStyledFlexWrap>
      <BigCoupon/>
      <Message>{props.message}</Message>
    </CustomizedStyledFlexWrap>
  )
}

const Message = styled(Typography)`
font-weight: bold;
font-size: 16px;
color: ${props => props.theme.palette.gray.gray04};
margin-top:  ${props => props.theme.spacing(1)}px;
`;

const StyledFlexWrap = styled(FlexWrap)`
display: flex;
flex-wrap: wrap;
flex-direction: column;
align-items: center;
margin-top: ${props => props.theme.spacing(10)}px;
`;

const CustomizedStyledFlexWrap = styled(StyledFlexWrap)`
margin-top: 50vw
`;
