import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

export const BackDropProgress = (props) =>{
  return(
    <StyledBackDrop  open={props.open}>
    <Message component='div'>{props.message}</Message>
    <CircularProgress color="inherit" />
    </StyledBackDrop>
  )
}

const StyledBackDrop = styled(Backdrop)`
z-index:${props => props.theme.zIndex.drawer+ 1};
color: #fff;
flex-direction: column;
`;

const Message = styled(Typography)`
color: #ffffff;
display:flex;
margin-bottom: 16px;
`;