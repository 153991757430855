/** action-creator */
export const setConsumerData= (value) => {
  return { type: 'SET_CONSUMERDATA', value: value };
};
export const resetConsumerData = (value) => {
  return { type: 'RESET_CONSUMERDATA', value: value };
};

/** reducer */
const consumerData = (state = null, action) => {
    switch (action.type) {
      case 'SET_CONSUMERDATA':
          var userProfile = Object.assign({},action.value);
          return userProfile;
      case 'RESET_CONSUMERDATA':
            return {};
      default:
        return state
    }
  }

  export default consumerData;