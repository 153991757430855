import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { CookonIcon } from '../../icons/icon';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '45%',
    height: 'auto',
    color: 'white',
    marginLeft: '8%',
    marginTop: theme.spacing(2)
  },
}));

export const AppLogo = (props) => {
  const classes = useStyles();
  return (
    <ListItem button>
      <Icon>
        <CookonIcon />
      </Icon>
      <Text primary={<StyledText>Cook on</StyledText>}/>
    </ListItem>
  );
};

const Text = styled(ListItemText)`
  color: white;
  text-decoration: none;
`;

const Icon = styled(ListItemIcon)`
  color: white;
`;

const StyledText = styled(Typography)`
font-family: Futura;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 29px;
letter-spacing: 0em;
text-align: left;

`
