import React from 'react';
import {PageTitle} from '../atoms/Title';
import CouponDetail from '../organisms/CouponDetail';

const CouponDetailPage = () => {
  return(
    <div>
    <CouponDetail/>
   </div>
  )
}

export default CouponDetailPage;