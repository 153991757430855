/** action-creator */
export const setStripeInfo = (value) => {
  return { type: 'SET_STRIPEINFO', value: value };
};
export const resetStripeInfo = (value) => {
  return { type: 'RESET_STRIPEINFO', value: value };
};

/** reducer */
const stripeInfo = (state = null, action) => {
  switch (action.type) {
    case 'SET_STRIPEINFO':
      return Object.assign({}, state, action.value);
    case 'RESET_STRIPEINFO':
      return {
        status: false,
        cardLast4: null,
        brand: null,
      };
    default:
      return state;
  }
};

export default stripeInfo;
