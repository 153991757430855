import React from 'react';
import CouponList from '../organisms/CouponList';
import {PageTitle} from '../atoms/Title';

const CouponListPage = () => {
  return (
    <div>
     <CouponList/>
    </div>
  )
}
export default CouponListPage;