import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import styled from "styled-components";
import Avatar from '@material-ui/core/Avatar';
import { Eliipsis } from '../../icons/icon';
import { nl2br } from '../../utils/nl2br';


const useStyles = makeStyles((theme) => ({
  smallColor: {
    fontSize: '2.6vw',
    lineHeight: '3.2vw',
  },
  small: {
    fontSize: '2.6vw',
    lineHeight: '3.2vw',
    fontWeight: 'bold',
  },
  default: {
    fontSize: '4vw',
    lineHeight: '4.2vw',
    fontWeight: 'bold',
  }
}));

export const DefaultText = (props) => {
  const classes = useStyles();
  return (
    <Typography gutterBottom classes={{ root: classes.default }}>
      {props.children}
    </Typography>
  );
}

export const ColorText = (props) => {
  const classes = useStyles();
  return (
    <Typography color="textSecondary" gutterBottom classes={{ root: classes.default }}>
      {props.children}
    </Typography>
  );
}

export const SmallText = (props) => {
  const classes = useStyles();
  return (
    <Typography color="textPrimary" gutterBottom classes={{ root: classes.small }}>
      {props.children}
    </Typography>
  );
}

export const SmallColorText = (props) => {
  const classes = useStyles();
  return (
    <Typography color="textSecondary" gutterBottom classes={{ root: classes.smallColor }}>
      {props.children}
    </Typography>
  );
}


export const BodyTitle = styled(Typography).attrs({
  variant: 'h6',
})`
    margin:${props => props.theme.spacing(1)}px ${props => props.theme.spacing(2)}px 0;
    font-weight:700;
    width: 100%;
    word-wrap: break-word;
`

export const BodyText = styled(Typography).attrs({
  variant: 'body1',
})`
    margin:${props => props.theme.spacing(1)}px ${props => props.theme.spacing(2)}px ${props => props.theme.spacing(2)}px;
    font-weight:400;
    width: 100%;
    word-wrap: break-word;
`

export const Caption = styled(Typography).attrs({
  variant: 'body2',
})`
    margin:${props => props.theme.spacing(1)}px ${props => props.theme.spacing(2)}px ${props => props.theme.spacing(2)}px;
    font-weight:300;
`

export const Description = (props) => {
  const title = props.title;
  const text = props.text;
  return (
    <Div>
      <Title variant="body1" gutterBottom>{title}</Title>
      <BoldText>{text}</BoldText>
    </Div>
  );
}

export const Comment = (props) => {
  const avatar = props?.avatar;
  const nickName = props?.nickName;
  const message = props?.message;
  const updateAt = props?.updateAt;
  const activeEclipse = props.activeEclipse;
  const isOpenReplyForm = props.isOpenReplyForm ? props.isOpenReplyForm : false;
  const setIsOpenReplyForm = props.setIsOpenReplyForm ? props.setIsOpenReplyForm : () => { };
  const onClick = props.onClick ? props.onClick : () => { };
  const isOpenReply = props.isOpenReply ? props.isOpenReply : false;
  const setIsOpenReply = props.setIsOpenReply ? props.setIsOpenReply : () => { };
  const numberOfReply = props.numberOfReply ? props.numberOfReply : 0
  return (
    <>
      <StyledDiv>
        <div>
          <CircledAvatar src={avatar} />
        </div>
        <ComentWrap>
          <UserName>{nickName}</UserName>
          <CommentArea>{nl2br(message)}</CommentArea>
          <div style={{ display: 'flex', position: 'relative' }} >
            <GrayText>{updateAt}</GrayText>
            <GrayText onClick={setIsOpenReplyForm} style={{ cursor: 'pointer' }}>返信する</GrayText>
            {activeEclipse &&
              <StyledEliipsis onClick={onClick} style={{ cursor: 'pointer' }}>
                <Eliipsis />
              </StyledEliipsis>}
          </div>
          {(numberOfReply !== 0 && numberOfReply !== '') && <GrayText onClick={setIsOpenReply} style={{ cursor: 'pointer' }}>{isOpenReply ? `${numberOfReply}件の返信を非表示` : `${numberOfReply}件の返信を表示`}</GrayText>}
          <div>
            {props.children}
          </div>
        </ComentWrap>
      </StyledDiv>
      <Line />
    </>
  )
}

export const Reply = (props) => {
  const avatar = props.avatar;
  const nickName = props?.nickName;
  const message = props?.message;
  const updateAt = props?.updateAt;
  const activeEclipse = props.activeEclipse;
  const sendFrom = props?.sendFrom;
  const consumerId = props?.consumerId;
  const onClick = props.onClick ? props.onClick : () => { };
  return (
    <>
      <StyledDiv>
        <div>
          <CircledAvatar src={avatar} />
        </div>
        <ReplyWrap>
          <ReplyUserName>{nickName}</ReplyUserName>
          <CommentArea>{nl2br(message)}
          </CommentArea>
          <div style={{ display: 'flex' }}><GrayText>{updateAt}</GrayText></div>
          {activeEclipse && sendFrom.parent.path === 'consumers' && sendFrom.id === consumerId &&
            <StyledReplyElipsis onClick={onClick} style={{ cursor: 'pointer' }}>
              <Eliipsis />
            </StyledReplyElipsis>}
        </ReplyWrap>
      </StyledDiv>
    </>
  )
}


const Div = styled.div`
    display:flex;
    flex-wrap: wrap;
    @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
    }
`;

const Title = styled(Typography)`
margin:${props => props.theme.spacing(2)}px ${props => props.theme.spacing(1.5)}px 0 ${props => props.theme.spacing(3)}px;
width:100px;
//font-family: Noto Sans JP;
font-style: normal;s
line-height: 20px;
color: #2B2B2B;
  //font-family: Noto Sans JP;
  font-style: normal;
  font-size: 11px;
  line-height: 20px;
  color: #2B2B2B;
}
` ;

const BoldText = styled(Typography)`
font-weight: bold;
font-size: 11px;
margin:${props => props.theme.spacing(2)}px ${props => props.theme.spacing(0)}px 0;
`;


const StyledDiv = styled(Div)`
align-items: flex-start;
width: 100%;

`;

export const CircledAvatar = styled(Avatar)`
  width: 24px;
  height: 24px;
  margin:${props => props.theme.spacing(2)}px ${props => props.theme.spacing(1)}px  ${props => props.theme.spacing(1)}px ${props => props.theme.spacing(1)}px;
`;

const Line = styled.div`
width: 100vw;
height: 1px;
background: #DDDDDD;
margin-left: -8px;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  display: none;
  margin-left: 0px;
  width: 100%;
}
`;

const ComentWrap = styled.div`
 margin-top:${props => props.theme.spacing(1)}px;
 margin-bottom:${props => props.theme.spacing(1)}px;
 flex: 1;
`;

const ReplyWrap = styled(ComentWrap)`
position: relative;
`;

const StyledEliipsis = styled.div`
position: absolute;
right: ${props => props.theme.spacing(2)}px;
`;

const StyledReplyElipsis = styled(StyledEliipsis)`
bottom: ${props => props.theme.spacing(0.5)}px;
`;


const UserName = styled(Typography)`
  font-size: 12px;
  height: 17px;
  color: #8F8F8F;
  margin-top: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 300px;
  white-space: nowrap;
`;

const ReplyUserName = styled(UserName)`
width: 200px;
`;

const CommentArea = styled(Typography)`
font-size: 13px;
margin-top: ${props => props.theme.spacing(0.5)}px;
margin-right: ${props => props.theme.spacing(2)}px;
line-height: 152.9%
`;


const GrayText = styled(Typography)`
font-size: 12px;
color: #8F8F8F;
margin-top: 2px;
margin-right: ${props => props.theme.spacing(2)}px;
`;



