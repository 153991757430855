/** action-creator */
export const login = (value) => {
  return { type: 'LOGIN', value: value };
};
export const logout = (value) => {
  return { type: 'LOGOUT', value: value };
};

/** reducer */
const logined = (state = false, action) => {
    switch (action.type) {
      case 'LOGIN':
        return true;
      case 'LOGOUT':
        return false;
      default:
        return state
    }
  }

  export default logined;
