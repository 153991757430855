import React, { useEffect } from 'react';
import liff from '@line/liff';
import { liffId } from '../../config';
import { lineLogin } from '../../firebase/auth/lineLogin';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const LoginPage = () => {
  const history = useHistory();
  const logined = useSelector(state => state.logined);
  useEffect(() => {
    if (!logined) {
      // 未ログイン
      liff.ready.then(()=>{
        history.push('/auth');
      });
    } else {
      // liff.initしないとqueryParamがあるとき、パスにliff.state=? が含まれてしまう
      liff.ready.then(() => {
          const urlIncludeQueryParam = window.location.search;
          const startQueryParamIndex = urlIncludeQueryParam.indexOf("?");
          if (urlIncludeQueryParam) {
            history.push('/' + urlIncludeQueryParam.substr(startQueryParamIndex + 1));
          } else {
            history.push('/my');
          }
        })
    }
  }, [])
  return (null)
}
export default LoginPage;
