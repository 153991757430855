/** action-creator */
export const setCustomerId = (value) => {
  return { type: 'SET_CUSTOMERID', value: value };
};
export const resetCustomerId = (value) => {
  return { type: 'RESET_CUSTOMERID', value: value };
};

/** reducer */
const customerId = (state = null, action) => {
    switch (action.type) {
      case 'SET_CUSTOMERID':
        return true;
      case 'RESET_CUSTOMERID':
        return false;
      default:
        return state;
    }
  }

  export default customerId;