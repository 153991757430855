/** action-creator */
export const setLiffUrlQueryParam= (value) => {
  return { type: 'SET_LIFFURL_QUERY_PARAM', value: value };
};
export const resetLiffUrlQueryParam = (value) => {
  return { type: 'RESET_LIFFURL_QUERY_PARAM', value: value };
};

/** reducer */
const liffUrlQueryParam = (state = null, action) => {
    switch (action.type) {
      case 'SET_LIFFURL_QUERY_PARAM':
          var newValue = action.value.slice();
          return newValue;
      case 'RESET_LIFFURL_QUERY_PARAM':
            return [];
      default:
        return state
    }
  }

  export default liffUrlQueryParam;
