import React, { useMemo } from 'react';
import { CouponChip } from '../atoms/Chip';
import Card from '@material-ui/core/Card';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { CouponAvatar, CouponMiddleAvatar } from '../atoms/Avatar';
import CardActionArea from '@material-ui/core/CardActionArea';
import { CouponButton } from '../atoms/Button';
import { Description } from '../atoms/Text';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment-timezone';
import { couponStatus } from '../../static/data';
import { useHistory } from 'react-router-dom';

export const Coupon = (props) => {
  const history = useHistory();
  const { providerData, couponData, expireData, status, couponId } = props;
  const avatar = providerData?.avatar ? providerData.avatar : null;
  const nickName = providerData?.nickName ? providerData.nickName : null;
  const formatedexpireData = expireData ? Number(moment(expireData.seconds * 1000).format('YYYY')) === 2854 ? '無期限' : `${moment(expireData.seconds * 1000).format('YYYY年MM月DD日')}まで` : null;
  const label = couponStatus.find((doc) =>doc.status === status) ? couponStatus.find((doc) =>doc.status === status).label : null;
  const onMoveToDetail = () => {
    if(couponId){
      history.push(`/coupon-detail/${couponId}`);
    }
  }
  return(
  <>
  <StyledCard  variant="outlined" status={status === 'active' ? true : false}> 
  <CardActionArea  onClick={()=>onMoveToDetail()}>
   <StatusChip label={label} color={status === 'active' ? '#F2A599' :'#C3C3C3'}/>
   <Title>{couponData ? couponData.couponName : <Skeleton width={160}/>}</Title>
   <ExpireDate>{formatedexpireData ? `${formatedexpireData}` : <Skeleton width={160}/>}</ExpireDate>
   <CouponAvatar avatar={avatar}  title={<ProviderName>{nickName ? nickName : <Skeleton width={117}/>}</ProviderName>}/>
   </CardActionArea>
  </StyledCard>
  </>)
}

export const BigCouponCard = (props) =>{
  const { providerData, couponData, couponId, disabled } = props;
  const data = providerData?.data ? providerData.data : null;
  const avatar = data?.avatar ? data.avatar : null;
  const nickName = data?.nickName ? data.nickName : null;
  const period = couponData ? Number(couponData.period) : null;
  const expireData = period ? period === 9999 ? '無期限' : `${moment().add(period,'months').format('YYYY年MM月DD日')}まで` : null;
  const discountTarget = couponData ? couponData.discountTarget : null;
  const discount = useMemo(()=>{
    let discountScope = '';
    if(discountTarget?.subscription){
      discountScope += '会員' ;
    }
    if(discountTarget?.singleItem){
      discountScope += discountScope.length > 1 ? '、個別購入' : '個別購入';
    }
    if(discountTarget?.eventItem){
      discountScope += discountScope.length > 1 ?  '、イベント' :  'イベント';
    }
    return discountScope
  },[discountTarget]);
  const onClick = props.onClick ? props.onClick : () =>{}
  return(
    <StyledBigCard>
      <CouponMiddleAvatar avatar={avatar}  title={<ProviderName>{nickName ? nickName : <Skeleton width={100}/>}</ProviderName>}></CouponMiddleAvatar>
      <CouponName>{couponData ? couponData.couponName : <Skeleton/>}</CouponName>
      <CouponDicount>{couponData ? `${couponData.discountRate}%割引き` :  <Skeleton/>}</CouponDicount>
      <Description title={'クーポンコード'} text={couponData ? couponId : <Skeleton width={160}/>}/>
      <StyledDescription title={'有効期限'} text={expireData ? `${expireData}` : <Skeleton width={160}/>}/>
      <StyledDescription title={'適用対象'} text={discount ?  discount : <Skeleton width={160}/>}/>
      <ButtonWrap>
      <CouponButton color={'#F2A599'} disabled={disabled} onClick={onClick}>{!disabled ? 'クーポンを適用する': 'クーポン適用中...'}</CouponButton>
      </ButtonWrap>
    </StyledBigCard>
  )
}

export const AppliedBigCouponCard  = (props) =>{
  const { providerData, couponData, couponId, expireData, status } = props;
  const data = providerData?.data ? providerData.data : null;
  const avatar = data?.avatar ? data.avatar : null;
  const nickName = data?.nickName ? data.nickName : null;
  const period = couponData ? Number(couponData.period) : null;
  const discountTarget = couponData ? couponData.discountTarget : null;
  const discount = useMemo(()=>{
    let discountScope = '';
    if(discountTarget?.subscription){
      discountScope += '会員' ;
    }
    if(discountTarget?.singleItem){
      discountScope += discountScope.length > 1 ? '、個別購入' : '個別購入';
    }
    if(discountTarget?.eventItem){
      discountScope += discountScope.length > 1 ?  '、イベント' :  'イベント';
    }
    return discountScope
  },[discountTarget]);
  const formatedexpireData = expireData ? Number(moment(expireData.seconds * 1000).format('YYYY')) === 2854 ? '無期限' : `${moment(expireData.seconds * 1000).format('YYYY年MM月DD日')}まで` : null;
  const label = couponStatus.find((doc) =>doc.status === status) ? couponStatus.find((doc) =>doc.status === status).label : null;
  return(
    <CustomizedStyledBigCard>
      <StyledStatusChip label={status ? label : '使用中'} color={status === 'active' ? '#F2A599' :'#C3C3C3'}/>
      <CouponMiddleAvatar avatar={avatar}  title={<ProviderName>{nickName ? nickName : <Skeleton width={100}/>}</ProviderName>}></CouponMiddleAvatar>
      <CouponName>{couponData ? couponData.couponName : <Skeleton/>}</CouponName>
      <CouponDicount>{couponData ? `${couponData.discountRate}%割引き` :  <Skeleton/>}</CouponDicount>
      <Description title={'クーポンコード'} text={couponData ? couponId : <Skeleton width={160}/>}/>
      <StyledDescription title={'有効期限'} text={formatedexpireData ? `${formatedexpireData}` : <Skeleton width={160}/>}/>
      <CustomizedStyledDescription title={'適用対象'} text={discount  ?  discount : <Skeleton width={160}/>}/>
    </CustomizedStyledBigCard>
  )
}

const StyledCard = styled(Card)`
position:relative;
border-radius: 8px;
border: ${props => props.status  ? '1px solid #F2A599' : '1px solid #DDDDDD'};
margin: ${props => props.theme.spacing(2)}px;
width: calc(100% - ${props=>props.theme.spacing(2)}px * 2);
@media screen and (min-width: ${(props) =>
  props.theme.breakpoints.values.sm}px) {
    width: 450px;
    margin-left: auto;
    margin-right: auto;
}
height: 101px;
`;

const StatusChip = styled(CouponChip)`
position:absolute;
right: ${props => props.theme.spacing(1)}px;
top: ${props => props.theme.spacing(1)}px;
`;

const StyledStatusChip = styled(StatusChip)`
top: ${props => props.theme.spacing(1.5)}px;

`;

const Title = styled(Typography)`
font-size: 13px;
font-weight: bold;
margin-top: ${props => props.theme.spacing(1.5)}px;
margin-left: ${props => props.theme.spacing(2)}px;
width: calc(100% - 85px - ${props => props.theme.spacing(2)}px);
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
`;

const ExpireDate = styled(Typography)`
font-size: 12px;
margin-top: ${props => props.theme.spacing(0.6)}px;
margin-left: ${props => props.theme.spacing(2)}px;
color: #8F8F8F;
`;

const ProviderName = styled(Typography)`
font-size: 13px;
font-weight: bold;
width: 100%;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
`;

const StyledBigCard = styled(StyledCard)`
border: 1px solid #F2A599;
box-shadow: none;
width: calc(100% - ${props=>props.theme.spacing(2)}px * 2);
height:auto;
padding-bottom: 24px;
@media screen and (min-width: ${(props) =>
  props.theme.breakpoints.values.sm}px) {
    width: 450px;
    margin-left: auto;
    margin-right: auto;
}
`;

const CustomizedStyledBigCard = styled(StyledBigCard)`
border: 1px solid #DDDDDD;
position: relative;
min-height: 262px;
height:auto;
padding-bottom: 18px;
`;

const CouponName = styled(Typography)`
font-size: 18px;
font-weight: bold;
color: #F2A599;
margin-top: ${props => props.theme.spacing(1)}px;
margin-left: ${props => props.theme.spacing(3)}px;
margin-right: ${props => props.theme.spacing(3)}px;
`;


const CouponDicount = styled(Typography)`
font-size: 22px;
font-weight: bold;
color: #F2A599;
margin-top: 2px;
margin-left: ${props => props.theme.spacing(3)}px;
margin-right: ${props => props.theme.spacing(3)}px;
`;

const ButtonWrap = styled.div`
width: 100%;
text-align: center;
margin-top: ${props => props.theme.spacing(6.5)}px
`;

const StyledDescription =  styled(Description)`
margin-top: 12px;
`;

const CustomizedStyledDescription = styled(StyledDescription)`
margin-bottom:  ${props => props.theme.spacing(2)}px;
`;
