/** action-creator */
export const setLiffPath= (value) => {
  return { type: 'SET_LIFFPATH', value: value };
};
export const resetLiffPath = (value) => {
  return { type: 'RESET_LIFFPATH', value: value };
};

/** reducer */
const liffPath = (state = null, action) => {
    switch (action.type) {
      case 'SET_LIFFPATH':
          return action.value;
      case 'RESET_LIFFPATH':
            return null;
      default:
        return state
    }
  }

  export default liffPath;
