/** action-creator */
export const setContents= (value) => {
  return { type: 'SET_CONTENTS', value: value };
};
export const resetContents = (value) => {
  return { type: 'RESET_CONTENTS', value: value };
};

/** reducer */
const contents = (state = null, action) => {
    switch (action.type) {
      case 'SET_CONTENTS':
          var newArray = action.value.slice();
          return newArray;
      case 'RESET_CONTENTS':
            return [];
      default:
        return state
    }
  }
  export default contents;
