import * as algoliasearch from 'algoliasearch';
import { algoliaSearchKey, algoliaId, algoliaIndexId } from '../config'
const client = algoliasearch(algoliaId, algoliaSearchKey,);
const index = client.initIndex(algoliaIndexId);

export const algoliaSearch = async(query) => {
   const response = await index.search(query, {
    hitsPerPage: 100
  }).catch((err) => {
     throw err;
   })
   return response
}
