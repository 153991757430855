import * as firebase from 'firebase';
import { functions } from '../../firebase/index';
export const customTokenLogin = async (accessToken) => {
  /**
   * function 側のエンドポイントをgetVerifyAccessToken
   */
  const response = await functions
    .httpsCallable('onCall-getVerifyAccessToken')({
      accessToken: accessToken,
      userType: 'consumer',
    })
    .catch((err) => {
      console.log('tokenを取得できませんでした');
      //window.alert("tokenを取得できませんでした");
      throw err;
    });
  await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  return await firebase
    .auth()
    .signInWithCustomToken(response.data)
    .catch((err) => {
      console.log('ログインに失敗しました');
      //window.alert("ログインに失敗しました");
      throw err;
    });
};
