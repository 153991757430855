import { combineReducers } from 'redux';
import logined from './action_reducers/logined';
import consumerData from './action_reducers/consumerData';
import customerId from './action_reducers/customerId';
import follow from './action_reducers/follow';
import subscribe from './action_reducers/subscribe';
import likes from './action_reducers/likes';
import contents from './action_reducers/contents';
import history from './action_reducers/history';
import stripeInfo from './action_reducers/stripeInfo';
import liffUrlQueryParam from './action_reducers/liffUrlQueryParam';
import providerTab from './action_reducers/providerTab';
import cardModalState from './action_reducers/cardModalState';
import liffPath from './action_reducers/liffPath';
import coupons from './action_reducers/coupons';
import searchData from './action_reducers/searchData';

export default combineReducers({
  logined,
  consumerData,
  customerId,
  follow,
  subscribe,
  likes,
  contents,
  history,
  stripeInfo,
  liffUrlQueryParam,
  providerTab,
  cardModalState,
  liffPath,
  coupons,
  searchData,
});