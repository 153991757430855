import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import styled from "styled-components";
import Skeleton from '@material-ui/lab/Skeleton';
import { ModalChipIcon } from '../../icons/icon';

const useStyles = makeStyles(theme => ({
  defaultChip: {
    borderRadius: '6px',
    fontSize: '2.6vw',
    fontWeight: 'bold',
    background: '#BEC8C8',// theme.palette.primary.main,
    minWidth:'18.1vw',
    width: 'auto',
    height: '6.6vw',
    marginRight: '3.6vw',
    marginTop: '1vw',
    marginBottom: '1vw',
  },
  inActiveChip: {
    borderRadius: '6px',
    fontSize: '2.6vw',
    fontWeight: 'bold',
    width: '18.1vw',
    height: '6.6vw',
    marginRight: '3.6vw',
    marginTop: '1vw',
    marginBottom: '1vw',
  },
  labelChip:{
      borderRadius: '2px',
      fontSize: '2.9vw',
      height: '4.8vw',
      fontWeight: 'bold',
      width: 'auto',
      marginRight: '3.6vw',
      marginTop: '1vw',
      marginBottom: '1vw',
      padding: 0,
  },
  freelabelChip:{
    borderRadius: '2px',
    background: '#2699FB',
    color: 'white',
    fontSize: '2.9vw',
    height: '4.8vw',
    fontWeight: 'bold',
    width: 'auto',
    marginRight: '3.6vw',
    marginTop: '1vw',
    marginBottom: '1vw',
    padding: 0,
}
}))

export const DefaultChip = (props) => {
  const classes = useStyles();
  return (
      <Chip className={classes.defaultChip} label={props.label}/>
  );
}

export const DefaultInActiveChip = (props) => {
  const classes = useStyles();
  return (
      <Chip className={classes.inActiveChip} label={props.label}/>
  );
}

export const DefaultLabel = (props) => {
  const classes = useStyles();
  return (
      <Chip className={props.label ==="無料" ? classes.freelabelChip : classes.labelChip} label={props.label}/>
  );
}

export const SmallChip = styled(Chip).attrs({
  variant:"outlined",
  size:"small"
})`
`;

export const BasicChip = styled(Chip).attrs({
  variant:"outlined",
})`
margin: ${props => props.theme.spacing(0.5)}px;
color: #707070;
`;

export const SmallContainedChip= styled(Chip).attrs({
  size:"small",
  variant:"outlined",
})`
font-weight:700;
font-size:10px;
height: 20px;
@media screen and (min-width: ${(props) =>
  props.theme.breakpoints.values.sm}px) {
    font-size:12px;
    height: 24px;
}
`;

export const CouponChip = styled(Chip).attrs({
  variant:"outlined",
})`
margin: ${props => props.theme.spacing(0.5)}px;
min-width:73px;
height:24px;
font-weight:bold;
font-size: 12px;
color: ${props => props.color};
border: 1px solid ${props => props.color};
`;

export const StyledSmallChip = (props) => {
  const label = props ? props.label ? props.label : null:null;
  return(
  <Div>
    {label ? <SmallContainedChip label={label} color={'primary'}/> : <Skeleton height={24}/>}
  </Div>
  )
}
const  Div = styled.div`
margin: 0;
//width: 100%;
`

export const ModalBlackChip = (props) => {
  return <BlackChip size="small" label={props.label} icon={<ModalChipIcon style={{height:10, width: 10, marginRight: 5}}/>}/>
}

const BlackChip = styled(Chip).attrs({
  variant:"default",
})`
margin: ${props => props.theme.spacing(0.5)}px;
color: ${props => props.color};
border: 1px solid ${props => props.color};
background: rgba(0, 0, 0, 0.7);
color: ${props => props.theme.palette.primary.main};
border:none;
font-weight: 700;
padding: 0 ${props => props.theme.spacing(1.5)}px  0 ${props => props.theme.spacing(2)}px;
`;

