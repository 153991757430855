import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from "styled-components"
import Typography from '@material-ui/core/Typography';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import MovieIcon from '@material-ui/icons/Movie';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import AddIcon from '@material-ui/icons/Add';
import { LikeIcon, TicketIcon } from '../../icons/icon';
import { IconButton as MaterialIconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  default: {
    boxShadow: 'none',
    borderRadius: '1vw',
    padding: '4vw 9.8vw',
    lineHeight: 1,
    fontWeight: "bold",
    fontSize: '3.2vw',
    width: '70.1vw',
    height: '10.6vw,',
    backgroundColor: '#2699FB',
    color: 'white',

  },
  basic: {
    boxShadow: 'none',
    borderRadius: '1vw',
    padding: '4vw 9.8vw',
    textAlign: 'center',
    lineHeight: 1,
    fontWeight: "bold",
    fontSize: '3.2vw',
    width: '70.1vw',
    height: '10.6vw,',

  },
  small: {
    boxShadow: 'none',
    borderRadius: '5.3vw',
    padding: '1.8vw 6.2vw',
    lineHeight: 1,
    fontSize: '2.6vw',
    fontWeight: "bold",
  },
  smallWhite: {
    boxShadow: 'none',
    borderRadius: '5.3vw',
    padding: '1.8vw 6.2vw',
    lineHeight: 1,
    fontSize: '2.6vw',
    fontWeight: "bold",
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: theme.palette.secondary.contrastText,
    color: theme.palette.secondary.secondary
  },
  floating: {
    borderRadius: '2.7vw',
    width: '87vw',
    position: 'fixed',
    bottom: '6.5vw',
    left: '6.5vw',
    zIndex: 999,
  },
  upload: {
    width: '39.4vw',
    height: '12.9vw',
    boxShadow: '0 3px 6px #00000029',
  },
  cancel: {
    width: '18.1vw',
    height: '6.6vw',
    fontSize: '2.6vw',
    boxShadow: 'none',
    fontWeight: 'bold',
    color: 'white'
  },
  photo: {
    cursor: 'pointer',
    color: '#BABAB9',
  },
}));




export const SmallGrayButton = (props) => {
  const classes = useStyles();
  return (
    <Button
      onClick={props.onClick ? props.onClick : () => { }}
      classes={{ root: classes.small }}
      color="secondary"
      disableElevation
      variant="contained">{props.children}</Button>
  );
}
export const SmallWhiteButton = (props) => {
  const classes = useStyles();
  return (
    <Button
      onClick={props.onClick ? props.onClick : () => { }}
      classes={{ root: classes.smallWhite }}
      disableElevation
      variant="contained">{props.children}</Button>
  );
}

export const FloatingButton = (props) => {
  const classes = useStyles();
  return (
    <Button
      disabled={props.disabled}
      classes={{ root: classes.floating }}
      onClick={props.onClick ? props.onClick : () => { }}
      color="primary"
      disableElevation
      variant="contained">{props.children}</Button>
  );
}


export const CancelButtom = (props) => {
  const classes = useStyles();
  return (
    <Button
      disabled={props.disabled}
      classes={{ root: classes.cancel }}
      onClick={props.onClick ? props.onClick : () => { }}
      color="primary"
      disableElevation
      variant="contained">{props.children}</Button>
  );
}


export const BasicButton = (props) => {
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => { };
  return (
    <StyledButton
      variant="contained"
      color="primary"
      disabled={disabled}
      disableElevation
      startIcon={props.disabled ? <CircularProgress size={20} /> : null}
      onClick={onClick}>
      {props.children}
    </StyledButton>
  )
}

export const DefaultButton = (props) => {
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => { };
  return (
    <StyledDefaultButton
      variant="outlined"
      color="default"
      disabled={disabled}
      disableElevation
      startIcon={props.disabled ? <CircularProgress size={20} /> : null}
      onClick={onClick}>
      {props.children}
    </StyledDefaultButton>
  )
}

export const ModalButton = (props) => {
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => { };
  return (
    <StyledModalBotton
      variant="contained"
      color="primary"
      disabled={disabled}
      disableElevation
      startIcon={props.disabled ? <CircularProgress size={20} /> : null}
      onClick={onClick}>
      {props.children}
    </StyledModalBotton>
  )
}


export const SmallButton = styled(Button).attrs({
  variant: "outlined",
  color: "primary"
})`
    margin:${props => props.theme.spacing(0.5)}px;
`;

export const SubmitButton = (props) => {
  return (
    <StyledButton
      disabled={props.disabled}
      onClick={props.onClick ? props.onClick : () => { }}
      disableElevation
      type="submit"
      disabled={props.disabled ? props.disabled : false}
      color="primary"
      variant="contained"
      startIcon={props.disabled ? <CircularProgress size={20} /> : null}>
      {props.children}
    </StyledButton>
  );
}


export const UploadButton = (props) => {
  const title = props.title;
  const required = props.required ? props.required : false;
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => { };
  return (
    <Div>
      <Title data-required={required} variant="body1" gutterBottom>{title}</Title>
      <UpButton startIcon={<CloudUploadIcon />} variant="outlined" color="primary" disabled={disabled} onClick={onClick}>
        {props.children}
      </UpButton>
    </Div>
  )
}

export const DownloadButton = (props) => {
  const title = props.title;
  const required = props.required ? props.required : false;
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => { };
  return (
    <Div>
      <Title data-required={required} variant="body1" gutterBottom>{title}</Title>
      <UpButton startIcon={<CloudDownloadIcon />} variant="contained" color="primary" disabled={disabled} onClick={onClick}>
        {props.children}
      </UpButton>
    </Div>
  )
}

export const UploadRoundButton = (props) => {
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => { };
  return (
    <RoundButton startIcon={<MovieIcon />} variant="outlined" color="primary" disabled={disabled} onClick={onClick} >
      {props.children}
    </RoundButton>
  )
}

export const IconButton = (props) => {
  const title = props.title;
  const icon = props.icon;
  const required = props.required ? props.required : false;
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => { };
  return (
    <Div>
      <Title data-required={required} variant="body1" gutterBottom>{title}</Title>
      <UpButton startIcon={icon} variant="outlined" color="primary" disabled={disabled} onClick={onClick}>
        {props.children}
      </UpButton>
    </Div>
  )
}

export const UploadDottedButton = (props) => {
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => { };
  const title = props.title;
  const required = props.required ? props.required : false;
  return (
    <Div>
      <Title data-required={required} variant="body1" gutterBottom>{title}</Title>
      <DottedButton endIcon={<PictureAsPdfIcon />} variant="outlined" color="primary" disabled={disabled} onClick={onClick} >
        {props.children}
      </DottedButton>
    </Div>
  )
}

export const UploadRegistrationButton = (props) => {
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => { };
  const title = props.title;
  const required = props.required ? props.required : false;
  const classes = useStyles();
  return (
    <Div>
      <Title
        data-required={required}
        variant="body1"
        gutterBottom
      >
        {title}
      </Title>
      <SimpleButton
        endIcon={<AddIcon
          className={classes.photo}
          onClick={onClick}
        />}
        variant="outlined" color="primary"
        disabled={disabled}
        onClick={onClick}
      >
        {props.children}
      </SimpleButton>
    </Div>
  )
}

export const DeleteContentButton = (props) => {
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => { };
  const title = props.title;
  const required = props.required ? props.required : false;
  return (
    <Div>
      <Title data-required={required} variant="body1" gutterBottom>{title}</Title>
      <FileNameWrap>
        <FileName noWrap={true} component='div'>fileNamefileNamefileNamefileNamefileNamefileName</FileName>
        <DeleteButton onClick={onClick} disabled={disabled}>削除</DeleteButton>
      </FileNameWrap>
    </Div>
  )
}

export const FollowButton = (props) => {
  const value = props.value ? props.value : false;
  const onClick = props.onClick ? props.onClick : () => { };
  return (
    <StyledFollowedButton
      onClick={onClick}
      disableElevation
      startIcon={!value && <AddIcon color='primary' />}
      value={value}
      variant={!value ? 'outlined' : 'contained'}
      color={'secondary'}
    >{!value ? 'フォロー' : 'フォロー中'}
    </StyledFollowedButton>
  )
}

export const LikeButton = (props) => {
  const value = props.value ? props.value : false;
  const onClick = props.onClick ? props.onClick : () => { };
  const likeNumber = props ? props.likeNumber ? props.likeNumber : null : null;
  return (
    <>
      {likeNumber ?
        <StyledRoundIcon
          onClick={onClick}
          variant={'outlined'}
          color={value ? 'secondary' : 'default'}
          value={value}
          startIcon={value ? 
            <>
              <LikeIcon 
                style={{ marginRight: 0, marginLeft: 0 }}
              />
            </> : 
            <LikeIcon 
              color={'#C3C3C3'} 
              style={{ marginRight: 0, marginLeft: 0 }} 
            />
          }
        >
          {likeNumber}
        </StyledRoundIcon> :
        <StyledMaterialRoundIcon 
          variant="outlined" 
          onClick={onClick} 
          startIcon={<LikeIcon color={'#C3C3C3'} style={{ marginRight: 0, marginLeft: 0, width: '18px' }} />} 
        />}
    </>
  )
}
export const MiddleIconButton = (props) => {
  const onClick = props.onClick ? props.onClick : () => { };
  return (
    <MiddleButton
      onClick={onClick}
      variant={'outlined'}
      startIcon={props.Icon ? props.Icon : null}
      endIcon={props.endIcon ? props.endIcon : null}
    ><MiddleSpan>{props.children}</MiddleSpan>
    </MiddleButton>
  )
}

export const MinButton = (props) => {
  const onClick = props.onClick ? props.onClick : () => { };
  return (
    <StyledMinButton
      onClick={onClick}
      variant={'contained'}
    >{props.children}
    </StyledMinButton>
  )
}


export const CouponButton = (props) => {
  const classes = useStyles();
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => { };
  return (
        <StyledCouponButton
          variant="contained"
          color={'#F2A599'}
          disabled={disabled}
          disableElevation
          startIcon={props.disabled ? <CircularProgress size={20} /> : null}
          onClick={onClick}>
          {props.children}
        </StyledCouponButton>
      )
}
export const RoundCouponButton = (props) => {
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => {};
  
  return (
        <RoundStyledCouponButton
          variant="contained"
          color={props.color ? props.color : '#F2A599'}
          disabled={disabled}
          disableElevation
          startIcon={props.disabled ? <CircularProgress size={20} /> : null}
          onClick={onClick}>
          {props.children}
        </RoundStyledCouponButton>
      )
}
export const RoundPrimaryButton = (props) => {
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => {};
  
  return (
        <StyledPrimary
          variant="contained"
          color={'primary'}
          disabled={disabled}
          disableElevation
          startIcon={props.disabled ? <CircularProgress size={20} /> : null}
          onClick={onClick}>
          {props.children}
        </StyledPrimary>
      )
}
export const RoundCouponOutlineButton = (props) => {
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => { };
  return (
        <RoundStyledCouponOutlineButton
          variant="outlined"
          color={'#C3C3C3'}
          disabled={disabled}
          disableElevation
          startIcon={props.disabled ? <CircularProgress size={20} /> : null}
          onClick={onClick}>
          {props.children}
        </RoundStyledCouponOutlineButton>
      )
}

export const AppliedCouponButton = (props) => {
  const onClick = props.onClick ? props.onClick : () => {};
  return (
    <StyledAppliedButton
      variant="contained"
      disableElevation
      startIcon={<TicketIcon />}
      onClick={onClick}>
      {props.children}
    </StyledAppliedButton>
  )
}

export const PrimaryButton = (props) => {
  const onClick = props.onClick ? props.onClick : () => {};
  const status = props.status;
  return (
    <CustomizedPrimary
      variant={status ? 'contained' : 'outlined'}
      color={'primary'}
      status={status}
      disabled={props.disabled}
      startIcon={props.disabled ? <CircularProgress size={20} /> : null}
      disableElevation
      onClick={onClick}>
      {props.children}
    </CustomizedPrimary>
  )
}


const StyledButton = styled(Button)`
    width:calc(100% - ${props => props.theme.spacing(4)}px * 2);
    height: 50px;
    font-weight: 700;
    box-shadow: none;
    color: white;
    border-radius: 8px;
    font-size: 16px;
    line-height: 23px;
    margin:${props => props.theme.spacing(4)}px;
    @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
        width:327px;
    }
`;

const StyledDefaultButton = styled(StyledButton)`
color: #C3C3C3;
margin: ${props => props.theme.spacing(2)}px ${props => props.theme.spacing(4)}px 0 ${props => props.theme.spacing(4)}px;
`;

const StyledCouponButton = styled(Button)`
width:300px;
height: 40px;
font-weight: 700;
box-shadow: none;
color: white;
border-radius: 8px;
font-size: 16px;
line-height: 23px;
background-color: #F2A599;
`;

const StyledPrimary = styled(Button)`
width:124px;
height: 50px;
border-radius: 32px;
margin: ${props => props.theme.spacing(1.5)}px;
font-weight: 700;
box-shadow: none;
color: white;
font-size: 16px;
line-height: 23px;
`;

const CustomizedPrimary = styled(Button)`
min-width:230px;
height: 42px;
border-radius: 8px;
margin: ${props => props.theme.spacing(1.5)}px;
font-weight: 700;
box-shadow: none;
color: ${props => props.status ? 'white' : 'primary'};
background:${props => !props.status && 'white'};
border: 1px solid primary;
font-size: 16px;
line-height: 23px;
&:hover {
  background-color: ${props => !props.status && 'white'};
}
`;


const RoundStyledCouponButton = styled(StyledCouponButton)`
width:124px;
height: 50px;
border-radius: 32px;
margin: ${props => props.theme.spacing(1.5)}px
`;

const RoundStyledCouponOutlineButton = styled(Button)`
width:124px;
height: 50px;
font-weight: 700;
box-shadow: none;
border-radius: 32px;
margin: ${props => props.theme.spacing(1.5)}px;
color: #C3C3C3;
border: 1px solid #C3C3C3;
`;

const StyledAppliedButton = styled(Button)`
width: calc(100% - ${props => props.theme.spacing(2) * 2}px);
height: 37px;
font-weight: 700;
color: #EF9E91;
background-color: rgba(242,165,153,0.3);
box-shadow: none;
width: calc(100% - 32px);
margin: 0 ${props => props.theme.spacing(2)}px  ${props => props.theme.spacing(2)}px  ${props => props.theme.spacing(2)}px;
border-radius: 8px;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  width: calc(100% - 32px)
}
&:hover {
  background-color: rgba(242,165,153,0.3);
}
`;





const StyledModalBotton = styled(Button)`
    width:204px;
    height: 42px
    height: 50px;
    font-weight: 700;
    box-shadow: none;
    color: white;
    border-radius: 8px;
    font-size: 16px;
    line-height: 23px;
    @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
    }
`;


const UpButton = styled(StyledButton)`
    margin:${props => props.theme.spacing(2)}px;
    @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
        margin:${props => props.theme.spacing(4)}px ${props => props.theme.spacing(2)}px;
    }
`;

const DeleteButton = styled(Button)`
width: 59px;
height: 40px;
background: #F4F4F4;
border: 1px solid #CED4D4;
box-sizing: border-box;
border-radius: 4px;
`;

const RoundButton = styled(Button)`
width: 208px;
height:49.2px;
background: #FFFFFF;
box-shadow: 0px 2px 10px rgba(92, 92, 92, 0.2);
border-radius: 38px;
border:none;
font-weight: bold;
margin-top: 105.4px;
margin-bottom:${props => props.theme.spacing(2)}px;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
    margin-top: 188.59px;
    margin-bottom:${props => props.theme.spacing(2.5)}px;
}
`;

const DottedButton = styled(Button)`
margin:${props => props.theme.spacing(4)}px  ${props => props.theme.spacing(2)}px ;
.MuiButton-endIcon {
  position:absolute;
  right: 12px;
}
.MuiButton-label {
  justify-content: flex-start;
}
width: 100%;
height: 45px;
background: #FFFFFF;
border: 1px dashed #CED4D4;
box-sizing: border-box;
border-radius: 4px;
margin-bottom:${props => props.theme.spacing(2)}px;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  width: 375px;
  height: 45px;
}
`;

const SimpleButton = styled(Button)`
margin:${props => props.theme.spacing(3)}px  ${props => props.theme.spacing(2)}px ;
.MuiButton-endIcon {
  position:absolute;
  right: 12px;
}
.MuiButton-label {
  justify-content: flex-start;
}
width: 100%;
height: 45px;
background: #FFFFFF;
color: #B0B0B0;
border: 1px solid #CED4D4;
box-sizing: border-box;
border-radius: 4px;
margin-bottom:${props => props.theme.spacing(2)}px;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  width: 241px;
  height: 45px;
}
`;

const Div = styled.div`
    display:flex;
    flex-wrap: wrap;
    @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
    }
`

const Title = styled(Typography)`
    margin:${props => props.theme.spacing(4)}px ${props => props.theme.spacing(2)}px 0;
    font-weight:700;
    width:calc(240px - ${props => props.theme.spacing(2)}px * 3);
    &[data-required="true"] {
        &::after{
          content:'*';
          margin-left:${props => props.theme.spacing(2)}px;
          font-size:14px;
          color:red;
        }
    }
`;

const FileNameWrap = styled.div`
display: flex;
justify-content: space-between;
width:calc(100% - ${props => props.theme.spacing(2)}px * 2);
margin:${props => props.theme.spacing(4)}px  ${props => props.theme.spacing(2)}px ${props => props.theme.spacing(2)}px ;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
    width:375px;
}
`

const FileName = styled(Typography)`
    font-weight:500;
    width:calc(240px - ${props => props.theme.spacing(2)}px * 3);
`;

const StyledFollowedButton = styled(Button)`
width: 103px;
height: 30px;
box-shadow: none;
box-sizing: border-box;
border-radius: 40px;
z-index:99; 
border: 1px solid;
font-weight: 700;
font-size: 12px;
letter-spacing: -0.333333px;
color: ${props => props.value ? 'white' : '#FF9B5C'};
&:hover {
  box-shadow: none;
}
}
`;

const StyledMinButton = styled(Button)`
width: 54px;
height: 24px;
font-size: 13px;
box-shadow: none;
box-sizing: border-box;
border-radius: 40px;
padiding: 0;
z-index:99;
&:hover {
  box-shadow: none;
}
}
`;

const StyledRoundIcon = styled(Button)`
height: 34px;
border-radius: 38px;
.MuiButton-startIcon:{
  margin-left:0;
  margin-right:0;
}
`;

const MiddleButton = styled(Button)`
min-width: 104px;
height: 37px;
background: #FFFFFF;
border: 1px solid ${props => props.theme.palette.gray.gray02};
border-radius: 8px;
font-weight: 700;
font-size: 12px;
color : ${props => props.theme.palette.gray.gray04};
margin: ${props => props.theme.spacing(1)}px;
justify-content: flex-start;
padding: 5px 12px;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  width: calc((100% - 8px * 6) / 3);
  text-align:center
}
`;

export const StyledLikeIconButton = styled(Button)`
width: 38px;
height: 34px;
background: white;
border-radius: 38px;
&:action{
  background: rgba(255,255,255, 0.8);
}
&:hover{
  background: rgba(255,255,255, 0.8);
}
`;



export const StyledIconButton = styled(Button)`
width: 38px;
height: 34px;
bottom: 0px;
right: 10px;
background: white;
border-radius: 38px;
&:action{
  background: rgba(255,255,255, 0.8);
}
&:hover{
  background: rgba(255,255,255, 0.8);
}
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  right: 20px;
}
`;

export const StyledIconRelateiveButton = styled(Button)`
margin: 0 0 0 auto;
width: 38px;
height: 34px;
top: 8.5px;
right: 20px;
background: white;
border-radius: 38px;
&:action{
  background: rgba(255,255,255, 0.8);
}
&:hover{
  background: rgba(255,255,255, 0.8);
}
`;

export const StyledMaterialRoundIcon = styled(StyledRoundIcon)`
min-width: 34px;
padding:0;
.MuiButton-startIcon {
  margin-left: auto;
  margin-right: auto;
}
`;

export const MiddleSpan = styled.span`
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  display:flex;
  justify-content: center;
  width: calc(100% - 56px);
}
`;