import React, { useCallback, useEffect, useState } from "react";
import { Coupon } from "../molecules/Coupon";
import { StyledFullPaper, StyledHalfFlexWrap } from "../atoms/Paper";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { firestoreProviders } from "../../firebase/firestore/firestoreProviders";
import styled from "styled-components";
import { NonCoupon } from "../atoms/NonContentComponent";

const CouponList = () => {
  const coupons = useSelector((state) => state.coupons);
  const [appliedCouponList, setAppliedCouponList] = useState(null);
  const history = useHistory();
  useEffect(() => {
    const getDetailInfo = async () => {
      const list = [];
      await Promise.all(
        coupons.map(async (coupon) => {
          const couponId = coupon.couponId;
          const providerId = coupon.data.providerRef.id;
          const expireData = coupon.data.expireData;
          const status = coupon.data?.status;
          const response = await Promise.all([
            firestoreProviders.getCouponData(providerId, couponId),
            firestoreProviders.getData(providerId),
          ]).catch((err) => {
            console.log(`${err}: err get coupon and provider data`);
            history.push("/error");
          });
          list.push({
            couponId: couponId,
            couponData: response[0],
            providerData: response[1],
            expireData: expireData,
            status: status,
          });
        })
      );
      setAppliedCouponList(list);
    };
    if (coupons) {
      getDetailInfo();
    }
  }, [coupons]);

  return (
    <CustomizedStyledFullPaper>
      {appliedCouponList ? (
        appliedCouponList.length > 0 ? (
          appliedCouponList.map((coupon) => {
            const couponData = coupon.couponData;
            const providerData = coupon.providerData;
            const expireData = coupon.expireData;
            const status = coupon.status;
            const couponId = coupon.couponId;
            return (
              <Coupon
                couponData={couponData}
                providerData={providerData}
                expireData={expireData}
                status={status}
                couponId={couponId}
              />
            );
          })
        ) : (
          <Message>
            <NonCoupon message={"クーポンがありません"} />
          </Message>
        )
      ) : null}
      <StyledHalfFlexWrap></StyledHalfFlexWrap>
    </CustomizedStyledFullPaper>
  );
};

export default React.memo(CouponList);

const CustomizedStyledFullPaper = styled(StyledFullPaper)`
  min-height: 60vh;
`;

const Message = styled.div`
  margin: ${(props) => props.theme.spacing(2)}px
    ${(props) => props.theme.spacing(2)}px;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    text-align: center;
  }
`;
