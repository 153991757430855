/** action-creator */
export const setSearchData = (value) => {
  return { type: 'SET_SEARCHDATA', value: value };
};
export const resetSearchData = (value) => {
  return { type: 'RESET_SEARCHDATA', value: value };
};

/** reducer */
const searchData = (state = null, action) => {
    switch (action.type) {
      case 'SET_SEARCHDATA':
          var newArray = action.value.slice();
          return newArray;
      case 'RESET_SEARCHDATA':
            return [];
      default:
        return state
    }
  }
  export default searchData;
