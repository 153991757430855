/** action-creator */
export const setCardModalState = (value) => {
  return { type: 'SET_CARDMODALSTATE', value: value };
};
export const resetCardModalState = (value) => {
  return { type: 'RESET_CARDMODALSTATE', value: value };
};

/** reducer */
const cardModalState = (state = false, action) => {
    switch (action.type) {
      case 'SET_CARDMODALSTATE':
        return action.value;
      case 'RESET_CARDMODALSTATE':
        return false;
      default:
        return state
    }
  }

  export default cardModalState;
