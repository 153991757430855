import React, { ReactNode, useCallback } from "react";
import styled from "styled-components";

export const nl2br = (text) => {
  const list = [];
  if (text != null) {
    const regex = /(\n)/g;
    const httpTag = "http://";
    const httpsTage = "https://";
    text.split(regex).map((line, i) => {
      if (line.match(regex)) {
        list.push(<br key={i} />);
      } else if (line.match(httpTag) || line.match(httpsTage)) {
        list.push(<a key={i} href={line} target="_blank" rel="noopener noreferrer">{line}</a>);
      } else {
        list.push(line);
      }
    });
  } else {
    list.push(<div />);
  }
  return list;
};

