/** action-creator */
export const setCoupons = (value) => {
  return { type: 'SET_COUPONS', value: value };
};
export const resetCoupons = (value) => {
  return { type: 'RESET_COUPONS', value: value };
};

/** reducer */
const coupons = (state = null, action) => {
    switch (action.type) {
      case 'SET_COUPONS':
          var newArray = action.value.slice();
          return newArray;
      case 'RESET_COUPONS':
            return [];
      default:
        return state
    }
  }
  export default coupons;
