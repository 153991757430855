import React, { useMemo} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import styled from 'styled-components';
import Skeleton from '@material-ui/lab/Skeleton';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import {Caption} from '../atoms/Text';
import PhotoIcon from '@material-ui/icons/Photo';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '21.3vw',
    height: '21.3vw',
    borderRadius: '50%',
  },
  small: {
    width: '10.6vw',
    height: '10.6vw',
    borderRadius: '50%',
  },
  profileSize: {
    width: '31.9vw',
    height: '31.9vw',
    borderRadius: '50%',
  },
}));

export const SimpleAvatar = (props) => {
  const classes = useStyles();
  return (
    <>
      {props.avatar ?
       <Avatar
        classes={{root :classes.root}}
        variant="square"
        src={ props.avatar}/> : <Skeleton  classes={{root :classes.root}} variant="circle"/>}
    </>
  );
}

export const SimpleAvatarSmall = (props) => {
  const classes = useStyles();
  return (
    <>
    {props.avatar ?
        <Avatar
        classes={{root :classes.small}}
        variant="square"
        src={ props.avatar　}/> : <Skeleton  classes={{root :classes.small}} variant="circle"/>}
    </>
  );
}

export const ProfileAvatar = (props) => {
  const classes = useStyles();
  return (
    <>
      {props.avatar ? (
        <Avatar
          classes={{ root: classes.profileSize }}
          variant="square"
          src={props.avatar}
        />
      ) : (
        <Skeleton classes={{ root: classes.profileSize }} variant="circle" />
      )}
    </>
  );
};



export const NamedAvatar = (props) => {
    const name = props.name;
    const caption = props.caption;
    const avatar = props.avatar;
    const onClick = props.onClick ? props.onClick : ()=>{};
    return(
        <RowWrap>
            <CircledAvatar src={avatar} onClick={onClick}/>
            <NameWrap>
                <Name>{name}</Name>
                <Caption>{caption}</Caption>
            </NameWrap>
        </RowWrap>);
}

export const EditableAvatar = (props) => {
    const title = props.title;
    const avatar = props.avatar;
    const required = props.required? props.required : false;
    const loading = props.loading ? props.loading : false;
    const onClick = props.onClick ? props.onClick : ()=>{};
    return(
      <Div>
      <EditableAvatarTitle data-required={required} variant="body1" gutterBottom component='div'>
        {title}
      </EditableAvatarTitle>
      {!avatar ?
        <RoundedAvatarWrap
          style={{ position: 'relative', width: 'auto', height: 'auto' }}
        >
          <RoundedAvatar
            variant="rounded"
            src={avatar}
          >
            {loading ? <CircularProgress /> : <PhotoIcon />}
          </RoundedAvatar>
          <StyledAvatarIconButton onClick={onClick} >
            <StyledAddAPhotoIcon color='primary' />
          </StyledAvatarIconButton>
        </RoundedAvatarWrap> :
        !loading ?
          <RoundedAvatarWrap
            style={{ position: 'relative', width: 'auto', height: 'auto' }}
          >
            <RoundedAvatar
              variant="rounded"
              src={avatar}
            />
            <StyledAvatarIconButton onClick={onClick}>
              <StyledAddAPhotoIcon color='primary' />
            </StyledAvatarIconButton>
          </RoundedAvatarWrap> :
          <RoundedAvatarWrap
            style={{ position: 'relative', width: 'auto', height: 'auto' }}
          >
            <RoundedAvatar variant="rounded" src={null} >
              {loading ? <CircularProgress /> : <PhotoIcon />}
            </RoundedAvatar>
          </RoundedAvatarWrap>}
    </Div>
    );
}
export const EditableThumbnail = (props) => {
  const title = props.title;
  const avatar = props.avatar;
  const required = props.required? props.required : false;
  const loading = props.loading ? props.loading : false;
  const onClick = props.onClick ? props.onClick : ()=>{};
  return(
      <Div>
          <Title data-required={required} variant="body1" gutterBottom>{title}</Title>
          <RoundedThumnail variant="rounded" src={avatar} onClick={onClick}>
              {loading ? <CircularProgress/> :<PhotoIcon/>}
              {!loading && <StyledIconButton><StyledAddAPhotoIcon color='primary'/></StyledIconButton>}
          </RoundedThumnail>
      </Div>
  );
}

export const MyMenuAvatar= (props) => {
    const title = props.title;
    const subtitle1 = props.subtitle1;
    const subtitle2 = props.subtitle2;
    const avatar = props.avatar;
    const required = props.required? props.required : false;
    const loading = props.loading ? props.loading : false;
    const onClick = props.onClick ? props.onClick : ()=>{};
    return(
        <MyMenuDiv>
            <StyledRoundedAvatar variant="rounded" src={avatar} onClick={onClick}>
                {loading ? <CircularProgress/> :<AddAPhotoIcon/>}
            </StyledRoundedAvatar>
            <div>
            <Maintitle data-required={required} variant="body1" gutterBottom>{title}</Maintitle>
            <Subtitle data-required={required} variant="body2" gutterBottom component='div'>{subtitle1}</Subtitle>
            <Subtitle data-required={required} variant="body2" gutterBottom component='div'>{subtitle2}</Subtitle>
            </div>
        </MyMenuDiv>
    );
}

export const ProviderAvatar= React.memo((props) => {
  const title = props.title;
  const subTitle1 = props.subTitle1;
  const subTitle2 = props.subTitle2;
  const avatar = props.avatar;
  const required = props.required? props.required : false;
  const loading = props.loading ? props.loading : false;
  const onClick = props.onClick ? props.onClick : ()=>{};
  return(
      <MyMenuDiv>
          <ProviderStyledRoundedAvatar variant="rounded" src={avatar} onClick={onClick}>
              {loading ? <CircularProgress/> : <PhotoIcon/>}
          </ProviderStyledRoundedAvatar>
          <div>
          <DefaultProviderMainTitle noWrap={true} data-required={required} variant="body1" gutterBottom>{title}</DefaultProviderMainTitle>
          <ProviderSubTitle noWrap={true} data-required={required} variant="body2" gutterBottom component='div'>{subTitle1}</ProviderSubTitle>
          <ProviderSubTitle noWrap={true} data-required={required} variant="body2" gutterBottom component='div'>{subTitle2}</ProviderSubTitle>
          </div>
      </MyMenuDiv>
  );
});
export const SmallProviderAvatar= React.memo(({title, subTitle1, subTitle2, avatar, required, loading, onClick}) => {
  return(
      <MyMenuDiv>
          <SmallProviderStyledRoundedAvatar variant="rounded" src={avatar} onClick={onClick ? onClick : ()=>{}}>
              {loading ? <CircularProgress/> : <PhotoIcon/>}
          </SmallProviderStyledRoundedAvatar>
          <div>
          <ProviderMainTitle noWrap={true} data-required={required} variant="body1" gutterBottom>{title !== null ? title : <Skeleton/>}</ProviderMainTitle>
          <ProviderSmallTitle noWrap={true} data-required={required} variant="body2" gutterBottom component='div'>{subTitle1 !== null ? subTitle1 : <Skeleton/>}</ProviderSmallTitle>
          <ProviderSmallTitle noWrap={true} data-required={required} variant="body2" gutterBottom component='div'>{subTitle2 !== null ? subTitle2 : <Skeleton/>}</ProviderSmallTitle>
          </div>
      </MyMenuDiv>
  );
})

export const ConsumerAvatar= React.memo(({title, avatar, required, loading, onClick}) => {
  return(
      <CustomerCardDiv style={{width:'100%'}}>
          <CustomerStyledRoundedAvatar variant="rounded" src={avatar} onClick={onClick ? onClick : ()=>{}}>
              {loading ? <CircularProgress/> :<PhotoIcon/>}
          </CustomerStyledRoundedAvatar>
          <NickNameWrap style={{flex: 1}}>
          <CustomizeDefaultProviderMainTitle noWrap={true} data-required={required} variant="body1" gutterBottom component='div'>{title !== null ? title : <Skeleton/>}</CustomizeDefaultProviderMainTitle>
           </NickNameWrap>
      </CustomerCardDiv>
  );
})

export const CouponAvatar= (props) => {
  const title = props.title;
  const avatar = props.avatar;
  return(
      <CustomizeDiv>
          <CouponRoundAvatar variant="rounded" src={avatar} >
              <PhotoIcon style={{width: '15px'}}/>
          </CouponRoundAvatar>
          <div>
          <CouponTitle variant="body1" gutterBottom>{title}</CouponTitle>
        </div>
      </CustomizeDiv>
  );
}

export const CouponMiddleAvatar= (props) => {
  const title = props.title;
  const avatar = props.avatar;
  return(
      <CouponMiddleDiv >
          <CouponMiddleRoundAvatar variant="rounded" src={avatar} >
              <PhotoIcon style={{width: '20px'}}/>
          </CouponMiddleRoundAvatar>
          <div>
          <CouponMiddleTitle variant="body1" gutterBottom>{title}</CouponMiddleTitle>
        </div>
      </CouponMiddleDiv>
  );
}




export const CircledAvatar = styled(Avatar)`
    margin:${props => props.theme.spacing(1)}px;
`;

export const SmallAvatar = styled(Avatar)`
    width: ${props => props.theme.spacing(3)}px;
    height: ${props => props.theme.spacing(3)}px;
`

const RowWrap = styled.div`
    display:flex;
    flex-wrap: wrap;
    flex-direction:row;
    align-items: center;
`

const NameWrap = styled.div`
    display:flex;
    flex-wrap: wrap;
    flex-direction:column;
`;

const Name = styled(Typography).attrs({
    variant:'body1',
})`
    margin:${props => props.theme.spacing(1)}px ${props => props.theme.spacing(2)}px 0;
    font-weight:700;
`
const Div = styled.div`
    display:flex;
    flex-wrap: wrap;
    flex-direction:column;
    @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
        align-items: center;
    }
`;

const CustomizeDiv = styled.div`
display:flex;
flex-wrap: wrap;
flex-direction:row;
`;

const Title = styled(Typography)`
    margin:${props => props.theme.spacing(4)}px ${props => props.theme.spacing(2)}px 0;
    font-weight:700;
    width:calc(240px - ${props => props.theme.spacing(2)}px * 3);
    &[data-required="true"] {
        &::after{
          content:'*';
          margin-left:${props => props.theme.spacing(2)}px;
          font-size:14px;
          color:red;
        }
    }
`

const EditableAvatarTitle = styled(Typography)`
    @media screen and (min-width: ${(props) =>
        props.theme.breakpoints.values.sm}px) {
            margin:${props => props.theme.spacing(4)}px ${props => props.theme.spacing(2)}px 0;
            font-weight: bold;
            text-align: center; 
            width:calc(240px - ${props => props.theme.spacing(2)}px * 3);
            &[data-required="true"] {
                &::after{
                  content:'*';
                  margin-left:${props => props.theme.spacing(2)}px;
                  font-size:14px;
                  color:red;
                }
            }
            color: #000;
        }
        @media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px){
          display: none;
        }
        margin: ${props => props.theme.spacing(2)}px auto 0 auto;
        width: 143px;
        height: 19px;
        font-family: Noto Sans JP,sans-serif;;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        /* identical to box height */
        text-align: center; 
`;

const MyMenuDiv = styled.div`
    display:flex;
    flex-wrap: wrap;
    flex-direction:row;
`;

const CustomerCardDiv = styled.div`
display:flex;
flex-wrap: wrap;
flex-direction:row;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  flex-direction: column;
  align-items: center;
}
`;

const Maintitle= styled(Typography)`
margin:${props => props.theme.spacing(2)}px ${props => props.theme.spacing(2)}px 0;
font-weight:700;
width:calc(240px - ${props => props.theme.spacing(2)}px * 4);
&[data-required="true"] {
    &::after{
      content:'*';
      margin-left:${props => props.theme.spacing(2)}px;
      font-size:14px;
      color:red;
    }
}
`;
const Subtitle = styled(Typography)`
    margin:${props => props.theme.spacing(1)}px ${props => props.theme.spacing(2)}px 0;
    font-weight:500;
    width:calc(240px - ${props => props.theme.spacing(2)}px * 4);
    @media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px){
      margin:${props => props.theme.spacing(0.5)}px ${props => props.theme.spacing(2)}px 0;
  }
`;


const ProviderMainTitle = styled(Maintitle)`
margin:10px ${props => props.theme.spacing(0.5)}px 0;
font-size: 13px;
width: 140px;
@media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px){
  margin:10px ${props => props.theme.spacing(0.5)}px 0;
}
@media screen and (min-width: 1000px){
  width: 450px;
}
`;

const CouponTitle = styled(Maintitle)`
margin:${props => props.theme.spacing(1.5)}px ${props => props.theme.spacing(0.5)}px 0;
font-size: 13px;
`;
 
const CouponMiddleTitle = styled(CouponTitle)`
margin:0;
`;


const DefaultProviderMainTitle = styled(ProviderMainTitle)`
margin:${props => props.theme.spacing(2)}px ${props => props.theme.spacing(0.5)}px 0;
font-size: 13px;
width: 125px;
@media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px){
  margin:${props => props.theme.spacing(2)}px ${props => props.theme.spacing(0.5)}px 0;
}
@media screen and (min-width: 1000px){
  width: 450px;
}
`;

const CustomizeDefaultProviderMainTitle = styled(DefaultProviderMainTitle)`
  margin:${props => props.theme.spacing(4.5)}px 16px ${props => props.theme.spacing(4.5)}px 16px;
  font-size:16px;
  @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
    width: calc(100% - 32px);
    margin:0px auto ${props => props.theme.spacing(3.5)}px auto;
    text-align: center;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px){
    margin:${props => props.theme.spacing(4.5)}px 0 ${props => props.theme.spacing(4.5)}px 16px;
    width:calc(100% - ${props => props.theme.spacing(2)}px * 3);
  }
`;

const ConsumerSubTitle= styled(Subtitle)`
margin:${props => props.theme.spacing(0.5)}px ${props => props.theme.spacing(0.5)}px;
font-size: 12px;
box-sizing: content-box;
overflow-wrap: normal;
@media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px){
  margin:${props => props.theme.spacing(0.5)}px ${props => props.theme.spacing(0.5)}px 0;
}
@media screen and (min-width: 1000px){
  width: 500px;
}
`

const ProviderSubTitle= styled(Subtitle)`
margin:${props => props.theme.spacing(0.5)}px ${props => props.theme.spacing(0.5)}px;
font-size: 12px;
width: 125px;
@media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px){
  margin:${props => props.theme.spacing(0.5)}px ${props => props.theme.spacing(0.5)}px 0;
}
@media screen and (min-width: 1000px){
  width: 450px;
}
`

const ProviderSmallTitle= styled(Subtitle)`
margin:${props => props.theme.spacing(0.5)}px ${props => props.theme.spacing(0.5)}px;
font-size: 12px;
width: 140px;
@media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px){
  margin:${props => props.theme.spacing(0.5)}px ${props => props.theme.spacing(0.5)}px 0;
}
@media screen and (min-width: 1000px){
  width: 450px;
}
`

const RoundedAvatar = styled(Avatar)`
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
    margin: 0;
    height: 100px;
    width: 100px;
    border-radius: 50%;
}
    margin:${props => props.theme.spacing(2)}px auto;
    height: 90px;
    width: 90px;
    border-radius: 50%;
`;

const StyledRoundedAvatar = styled(RoundedAvatar)`
margin:${props => props.theme.spacing(2)}px;
background: #E1E2E5;
height: 80px;
width: 80px;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  margin: ${props => props.theme.spacing(2)}px;
  height: 120px;
  width: 120px;
}
`
const ProviderStyledRoundedAvatar = styled(RoundedAvatar)`
margin:${props => props.theme.spacing(2)}px ${props => props.theme.spacing(1)}px ${props => props.theme.spacing(2)}px ${props => props.theme.spacing(2)}px;
background: #E1E2E5;
height: 72px;
width: 72px;
border-radius:50%;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  margin: ${props => props.theme.spacing(2)}px;
  border-radius:50%;
  height: 72px;
  width: 72px;
  margin:${props => props.theme.spacing(2)}px;
}
`;

const  CustomerStyledRoundedAvatar= styled(RoundedAvatar)`
margin:${props => props.theme.spacing(2)}px ${props => props.theme.spacing(1)}px ${props => props.theme.spacing(2)}px ${props => props.theme.spacing(2)}px;
background: #E1E2E5;
height: 72px;
width: 72px;
border-radius:50%;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  margin: ${props => props.theme.spacing(2)}px;
  border-radius:50%;
  height: 100px;
  width: 100px;
  margin:${props => props.theme.spacing(4)}px ${props => props.theme.spacing(1)}px ${props => props.theme.spacing(1.5)}px ${props => props.theme.spacing(1)}px;
}
`;

const SmallProviderStyledRoundedAvatar = styled(RoundedAvatar)`
margin: 10px ${props => props.theme.spacing(1)}px;
background: #E1E2E5;
height: 60px;
width: 60px;
border-radius:50%;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  margin: 10px ${props => props.theme.spacing(2)}px;
  height: 60px;
  width: 60px;
  border-radius:50%;
}
`;



const RoundedThumnail = styled(Avatar)`
position:relative;
margin:${props => props.theme.spacing(2)}px;
height: 126px;
width: 160px;
border-radius: 16px;
background: ${props => props.theme.palette.gray.gray01};
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){

}
`;

const StyledIconButton = styled(IconButton)`
background: #ffffff;
position: absolute;
width: 38px;
height: 38px;
bottom: 8px;
right: 10px;
background: #FFFFFF;
box-shadow: 0px 2px 10px rgba(92, 92, 92, 0.5);
`;

const StyledAddAPhotoIcon = styled(AddAPhotoIcon)`
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
margin: auto;
width: 23.11px;
height: 23.11px;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 21.11px;
  height: 21.11px;
}
`;


const RoundedAvatarWrap = styled.div`
position:relative;
margin:${props => props.theme.spacing(2)}px;
height: 120px;
width: 120px;
@media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px){
  margin:0 ${props => props.theme.spacing(2)}px;
}
`;


const StyledAvatarIconButton = styled(IconButton)`
background: #ffffff;
position: absolute;
width: 30px;
height: 30px;
bottom: 20px;
left: 50vw;
box-shadow: 0px 2px 10px rgba(92, 92, 92, 0.5);
&:action{
  background: rgba(255,255,255, 0.8);
}
&:hover{
  background: rgba(255,255,255, 0.8);
}
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  background: #ffffff;
  position: absolute;
  width: 38px;
  height: 38px;
  bottom: 5px;
  left: 75px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(92, 92, 92, 0.5);
  }
`;

const CouponRoundAvatar = styled(Avatar)`
margin:${props => props.theme.spacing(1)}px ${props => props.theme.spacing(1)}px ${props => props.theme.spacing(1)}px  ${props => props.theme.spacing(2)}px;
background: #E1E2E5;
height: 30px;
width: 30px;
border-radius:50%;
`;

const CouponMiddleRoundAvatar = styled(CouponRoundAvatar)`
width: 50px;
height: 50px;
margin:8px;
`;
const CouponMiddleDiv = styled(CustomizeDiv)`
align-items: center;
margin-left: ${props => props.theme.spacing(2)}px;
margin-top: ${props => props.theme.spacing(1.5)}px;
`;

const NickNameWrap = styled.div`
width: calc(100% - 72px - ${props=>props.theme.spacing(2)}px * 2);

`;


