import React,{useState , useEffect} from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import styled from 'styled-components';
import MovieIcon from '@material-ui/icons/Movie';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useHistory } from 'react-router-dom';
import SettingsIcon from '@material-ui/icons/Settings';
import { SearchIcon } from '../../icons/icon'

const FooterMenu = (props) =>{
  const [value, setValue] = React.useState(0);
  const [isScroll, setIsScroll] = useState(false);
  const history = useHistory();
  const pathname = history.location.pathname;
  const path = pathname.replace('/', '');
  useEffect(()=>{
    if(path)
    setValue(path)
  }, [path]);

  const handleChange = (event, newValue) => {
    switch (newValue) {
      case 'search':
        setValue(newValue);
        history.push('/search');
        break;
      case 'my':
        setValue(newValue);
        history.push('/my');
        break;
      case 'edit-profile':
        setValue(newValue);
        history.push('/edit-profile');
        break;
      default:
        break;
    }
  };
  return (
    <StyledBottomNavigation
      value={value}
      onChange={handleChange}
      showLabels
    >
      <StyledBottomNavigationAction label="さがす"  value="search" icon={<MovieIcon />} />
      <StyledBottomNavigationAction label="マイページ" value="my" icon={<AccountCircleIcon />} />
      <StyledBottomNavigationAction label="設定" value="edit-profile" icon={<SettingsIcon />} />
    </StyledBottomNavigation>
  )
}

export default FooterMenu;

const StyledBottomNavigation = styled(BottomNavigation)`
width: 100vw;
font-size: 12px;
height: 60px;
padding-bottom: env(safe-area-inset-bottom);
position: fixed;
z-index: 999;
bottom: 0;
touch-action: none;
@media screen and (min-width: ${(props) =>
  props.theme.breakpoints.values.sm}px) {
width: 800px;
left: calc(100vw / 2 - 408px);
}

`;

const  StyledBottomNavigationAction = styled(BottomNavigationAction)`
font-weight: 700;
font-size: 12px;
touch-action: none;
&:action {
  background-color: #00B900;
  opacity: 0.7;

};
.MuiBottomNavigationAction-label{
  font-size: 12px;
}
max-width: calc(100% / 3);
`