import firebase from 'firebase';
import "firebase/firestore";
import { firebaseConfig } from '../config.js';
import 'firebase/functions'

export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const auth =firebase.auth();
export const storage = firebase.storage();
export const functions = firebase.app().functions('asia-northeast1');
export const analytics =  firebase.analytics();
