import React from 'react';
import LoginPage from './../components/pages/LoginPage'
import { useSelector } from "react-redux";

const IsLogined =(props)=> {
  const logined = useSelector(state => state.logined);
    return (
      <div>
        {logined
          ? props.children
          : <LoginPage/>}
      </div>
    )
 }
export default IsLogined;
