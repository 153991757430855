import { firestore } from './firestore';

const providers = 'providers';
const hidden = 'hidden';
const stripeInfo = 'stripeInfo';
const profile = 'profile';
const followers = 'followers';
const contents = 'contents';
const views = 'views';
const likes = 'likes';
const singleItems = 'singleItems';
const coupons = 'coupons';
const comments = 'comments';
const replies = 'replies';

const ref = {
	collection: () => firestore.collection(providers),
	doc: (guestId) => ref.collection().doc(guestId),
	hiddenRef: (guestId) => ref.collection().doc(guestId).collection(hidden).doc(stripeInfo),
	hiddenProfileRef: (guestId) => ref.collection().doc(guestId).collection(hidden).doc(profile),
  followersRef: (userId, followerId) => ref.collection().doc(followerId).collection(followers).doc(userId),
  singleItemRef: (userId) => ref.collection().doc(userId).collection(singleItems),
  contentRef: (userId) => ref.collection().doc(userId).collection(contents),
  viewsRef:(providerId, contentId) => ref.collection().doc(providerId).collection(contents).doc(contentId).collection(views),
  contentDoc:(userId, contentId) => ref.collection().doc(userId).collection(contents).doc(contentId),
  singleItemDoc:(userId, contentId) => ref.collection().doc(userId).collection(singleItems).doc(contentId),
  likesRef: (contentId, providerId) =>  ref.collection().doc(providerId).collection(contents).doc(contentId).collection(likes),
  checklikesRef: (contentId, providerId, consumerId) =>  ref.collection().doc(providerId).collection(contents).doc(contentId).collection(likes).doc(consumerId),
  couponsRef: (providerId) =>  ref.collection().doc(providerId).collection(coupons),
  couponRef: (providerId, couponId) =>  ref.collection().doc(providerId).collection(coupons).doc(couponId),
  commentsRef: (providerId, contentId) => ref.collection().doc(providerId).collection(contents).doc(contentId).collection(comments),
  repliesRef: (providerId, contentId, commentId) => ref.commentsRef(providerId, contentId).doc(commentId).collection(replies),
};

export const firestoreProviders = {
	getCollectionRef: () => ref.collection(),
	getHiddenProfileRef: (userId) => ref.hiddenProfileRef(userId),
	getFollowersRef: (userId, followerId) => ref.followersRef(userId, followerId),
	getNewDocRef: () => ref.newDoc(),
  getRef: (guestId) => ref.doc(guestId),
  getContentRef: (userId) => ref.contentRef(userId),
  getCouponsRef: (providerId) =>ref.couponsRef(providerId),
  getCommentsRef: (providerId, contentId) => ref.commentsRef(providerId, contentId),
  getRepliesRef: (providerId, contentId, commentId) => ref.repliesRef(providerId, contentId, commentId),
  getViewsRef: (providerId, contentId) => ref.viewsRef(providerId, contentId),
	getAllData: async () => {
		const userDatas = await firestore.getAllDatas(ref.collection()).catch((err) => {
			console.log('FirestoreOrders getAllData err');
			throw err;
		});
		return userDatas;
	},
	getData: async (orderId) => {
		const data = await firestore.get(ref.doc(orderId)).catch((err) => {
			console.log('FirestoreOrders getRef err');
			throw err;
		});
		return data;
	},
	getContents: async (userId) => {
		return await firestore.getAllDatas(ref.contentRef(userId).orderBy("createAt", "desc")).catch((err) => {
			console.log('firestoreProviders getAllData err');
			throw err;
		});
  },
  getSingleItems:async (userId) => {
		return await firestore.getAllDatas(ref.singleItemRef(userId)).catch((err) => {
			console.log('firestoreProviders getAllData err');
			throw err;
		});
  },
	getContentData: async (userId, contentId) => {
		return await firestore.get(ref.contentDoc(userId, contentId)).catch((err) => {
			console.log('firestoreProviders getContentData err');
			throw err;
		});
  },
  getSingleItemData: async (userId, contentId) => {
		return await firestore.get(ref.singleItemDoc(userId, contentId)).catch((err) => {
			console.log('firestoreProviders getContentData err');
			throw err;
		});
  },
  getLikes: async(contentId, providerId) => {
    return await firestore.getAllDatas(ref.likesRef(contentId, providerId)).catch((err) => {
      console.log('firestoreProviders getAllData err');
      throw err
    })
  },
  getChecklikes: async(contentId, providerId, consumerId) => {
    return await firestore.get(ref.checklikesRef(contentId, providerId, consumerId)).catch((err) => {
      console.log('firestoreProviders getAllData err');
      throw err
    })
  },
  getComments: async(providerId, contentId) => {
    return await firestore.get(ref.commentsRef(providerId, contentId)).catch((err) => {
      console.log('firestoreProviders getComments err');
      throw err
    })
  },
	getRefStripeInfo: (guestId) => ref.hiddenRef(guestId),
  getCouponData: async(userId, couponId) => {
    return await firestore.get(ref.couponRef(userId, couponId)).catch((err) => {
     console.log('firestoreProviders getContentData err');
     throw err
    })
  },
};
