/** action-creator */
export const setHistory = (value) => {
	return { type: 'SET_HISTORY', value: value };
};
export const addHistory = (value) => {
	return { type: 'ADD_HISTORY', value: value };
};
export const resetHistory = (value) => {
	return { type: 'RESET_HISTORY', value: value };
};

/** reducer */
const history = (state = null, action) => {
	switch (action.type) {
		case 'SET_HISTORY':
			var newArray = action.value.slice();
			return newArray;
		case 'ADD_HISTORY':
			return state
				? state.length > 0
					? state.map((history) => {
							if (history.id === action.value.id) {
								return {
									...history,
									contentData: action.value.contentData
								};
							} else {
								return history;
							}
						})
					: state
				: state;
		case 'RESET_HISTORY':
			return [];
		default:
			return state;
	}
};
export default history;
