import { lineLoginConfig } from '../../config'

const base_url = lineLoginConfig.base_url
  + "?response_type=" + lineLoginConfig.response_type
  + "&client_id=" + lineLoginConfig.client_id
  + "&redirect_uri=" + lineLoginConfig.redirect_uri
  + "&scope=" + lineLoginConfig.scope;

export const lineLogin = async (state, liffUrlQueryParam) => {
  let redirectParam = state;
  if (liffUrlQueryParam) {
    for (let i = 0; i < liffUrlQueryParam.length; i++) {
      if (i === 0) {
        redirectParam = liffUrlQueryParam[0];
        continue;
      }
      redirectParam += '?' + liffUrlQueryParam[i];
    }
  }
  const url = base_url + "&state=" + redirectParam;
  window.location.href = url;
}
