import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  success: {},
  error: {},
}));

export default function CustomizedSnackbar(props) {
  const { snackState, setSnackState, marginTop } = props;
  const classes = useStyles();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackState({
      open: false,
      message: snackState.message,
      success: snackState.success,
    });
  };
  return (
    <Snackbar
      open={snackState.open}
      anchorOrigin={{
        horizontal: "center",
        vertical: "top",
      }}
      autoHideDuration={2000}
      onClose={handleClose}
      style={{ marginTop: `${marginTop}vh` }}
    >
      {snackState.success ? (
        <Alert
          style={{ width: "100%" }}
          severity="success"
          onClose={() => handleClose()}
        >
          {snackState.message}
        </Alert>
      ) : (
        <Alert
          style={{ width: "100%" }}
          severity="error"
          onClose={() => handleClose()}
        >
          {snackState.message}
        </Alert>
      )}
    </Snackbar>
  );
}
