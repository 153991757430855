/** action-creator */
export const setProviderTab = (value) => {
  return { type: 'SET_PROVIDERTAB', value: value };
};
export const resetProviderTab = (value) => {
  return { type: 'RESET_PROVIDERTAB', value: value };
};

/** reducer */
const providerTab = (state = null, action) => {
    switch (action.type) {
      case 'SET_PROVIDERTAB':
        return action.value;
      case 'RESET_PROVIDERTAB':
        return null;
      default:
        return state;
    }
  }

  export default providerTab;