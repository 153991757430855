import React from 'react';
import Typography from '@material-ui/core/Typography';
import styled from "styled-components";
import { FlexWrap } from './Paper';
import HttpsOutlinedIcon from '@material-ui/icons/HttpsOutlined';
import { LogoIcon, CookonTitle } from '../../icons/icon';

export const PageTitle = styled(Typography).attrs({
    varient: 'h2',
})`
    font-size: 1.4rem;
    font-weight:700;
    margin:${props => props.theme.spacing(3)}px ${props => props.theme.spacing(2)}px;
    vertical-align: top;
    @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    display:none
  }
`

export const SubTitle = (props) => {
    const {
        text
    } = props;
    return (
        <>
            <Div>
                <FlexWrap>
                    <HttpsOutlinedIcon style={{ color: '#8F8F8F' }} />
                    <SubTitleStyle>
                        {text}
                    </SubTitleStyle>
                </FlexWrap>
                <UnderBar/>
            </Div>
        </>
    );
}


export const MainTitle = ({title}) => {
  return(
    <MainDiv>
      <StyledMainTypography component="div">
      <LogoIcon style={{marignLeft:8}}/>
        {title}
      </StyledMainTypography>
    </MainDiv>
  )
}
export const CookonPcTitle = () => {
  return(
    <Wrap>
      <CookonTitle/>
    </Wrap>
  )
}


const SubTitleStyle = styled(Typography).attrs({
    varient: 'h2',
})`
height: 20px;
//font-family: Noto Sans JP;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 20px;
color: ${props => props.theme.palette.gray.gray05};
margin: ${props => props.theme.spacing(0.2)}px ${props => props.theme.spacing(1)}px;
vertical-align: top;
`

const UnderBar = styled.div`
width: 100%;
height: 0px;
border: 1px solid ${props => props.theme.palette.gray.gray05};
margin-top: 9px;
`

const Div = styled.div`
margin:${props => props.theme.spacing(4)}px ${props => props.theme.spacing(2)}px 0;
margin-bottom: 3.5vw;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
    margin:${props => props.theme.spacing(4)}px ${props => props.theme.spacing(2)}px 0;
    margin-bottom: 25px;
  }
`;

const MainDiv = styled.div`
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  display: none
}
width : 100%;
display:flex;
text-align:center;
font-family: Phosphate;
letter-spacing: -0.333333px;
color: #F5AA7B;
`;

const StyledMainTypography = styled(Typography).attrs({
  varient: 'h2',
})`
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  display: none
}
margin: auto;
font-weight: 700;
font-size: 22px;
`;

const Wrap = styled.div`
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  width : 100%;
  height: 50px;
  text-align: center;
}
//display: none
`;